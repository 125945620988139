import { useQuery } from '@tanstack/react-query'
import { setCookie, parseCookies, destroyCookie } from 'nookies'

import api from 'services/api'

import { withDelayedRetryBackoff } from 'utils/backoff'
import { getIntercomHash } from './intercom'

export const loginPatient = credentials =>
  api.post('/token/', credentials, {
    transformRequest: [
      (data, headers) => {
        headers['Content-Type'] = 'application/json;charset=UTF-8'
        delete headers.Authorization
        return JSON.stringify(data)
      }
    ]
  })

const cookies = parseCookies()

export const refreshTokenPatient = async refreshToken => {
  const tokenRequest = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token'
  }

  const credentialsResponse = await getCredentials()

  const tokenResponse = await api.post(
    '/token/',
    {
      ...credentialsResponse.data.results[0],
      ...tokenRequest
    },
    {
      transformRequest: [
        (data, headers) => {
          headers['Content-Type'] = 'application/json;charset=UTF-8'
          delete headers.Authorization
          return JSON.stringify(data)
        }
      ]
    }
  )
  setTokens(tokenResponse)
  const userResponse = await getUser()
  setProfile(userResponse.data.content.user)
  return tokenResponse
}

export const loginPsychologist = credentials => api.post('/prof_token/', credentials)

export const getCredentials = () =>
  api.get('/client_credentials/', {
    transformRequest: [
      (data, headers) => {
        headers['Content-Type'] = 'application/json;charset=UTF-8'
        delete headers.Authorization
        return JSON.stringify(data)
      }
    ]
  })

export const getUser = async (clearTrack, contract_id) => {
  if (clearTrack) {
    localStorage.removeItem('utm-tracking')
  }

  const user = getProfile()

  if (!cookies?.['@vittude:user']) {
    setCookie(
      null,
      '@vittude:user',
      JSON.stringify({
        contract: user?.contract,
        id: user?.id
      }),
      {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        secure: process.env.NODE_ENV === 'production'
      }
    )
  }

  const userMe = await withDelayedRetryBackoff(() => api.get('/me/', { params: { contract_id } }), {
    timeoutInMilliseconds: 500
  })
  const userIntercomHash = await withDelayedRetryBackoff(() => getIntercomHash(), {
    timeoutInMilliseconds: 500
  })
  userMe.data.content.user.intercom_hash = userIntercomHash.data.content.intercom_hash
  return userMe
}

export const logout = () => {
  const token = parseCookies()['vittude-token']
  return new Promise((resolve, reject) => {
    getCredentials().then(async res => {
      const { data: userData } = await getUser()
      api
        .post('/logout/', { token, ...res.data.results[0] })
        .then(async res => {
          if (process.browser) {
            clear()
          }
          try {
            await api.post('/event_messenger/notify/', {
              patient_id: userData.content.user.id,
              event_type: 'signout'
            })
          } catch {}
          resolve()
        })
        .catch(() => {
          if (process.browser) {
            clear()
          }
          reject() // eslint-disable-line
        })
    })
  })
}

export const clear = ({ includeProfile } = { includeProfile: true }) => {
  if (!process.browser) {
    return ''
  }

  if (includeProfile) localStorage.removeItem('vittude-profile')
  console.log('clear')
  destroyCookie(null, '@vittude:user')
  destroyCookie(null, 'vittude-token')
  destroyCookie(null, 'vittude-refresh-token')
  destroyCookie(null, 'vittude-totp')
}

export const setToken = token => {
  if (!process.browser) {
    return ''
  }
  setCookie(null, 'vittude-token', token, {
    maxAge: 90 * 24 * 60 * 60, // 90 days
    path: '/',
    domain: process.env.NODE_ENV === 'production' && '.vittude.com'
  })
}

export const setRefreshToken = refreshToken => {
  if (!process.browser) {
    return ''
  }
  setCookie(null, 'vittude-refresh-token', refreshToken, {
    maxAge: 90 * 24 * 60 * 60, // 90 days
    path: '/',
    domain: process.env.NODE_ENV === 'production' && '.vittude.com'
  })
}

export const setTokens = tokenResponse => {
  setToken(`${tokenResponse.data.content.token_type} ${tokenResponse.data.content.access_token}`)
  setRefreshToken(tokenResponse.data.content.refresh_token)
}

export const setProfile = profile => {
  try {
    if (!process.browser) {
      return ''
    }
    localStorage.setItem('vittude-profile', JSON.stringify(profile)) // eslint-disable-line
  } catch (error) {
    console.log(error)
  }
}

export const getProfile = () => {
  try {
    if (!process.browser) {
      return ''
    }
    const profile = JSON.parse(localStorage.getItem('vittude-profile')) // eslint-disable-line
    return profile
  } catch (error) {
    console.log(error)
  }
}

export const getToken = () => {
  try {
    if (!process.browser) {
      return ''
    }
    return parseCookies()['vittude-token'] || localStorage.getItem('vittude-token')
  } catch (error) {
    console.log(error)
  }
}

export const getRefreshToken = () => {
  try {
    if (!process.browser) {
      return ''
    }
    return parseCookies()['vittude-refresh-token'] || localStorage.getItem('vittude-refresh-token')
  } catch (error) {
    console.log(error)
  }
}

export const defaultUseMeParams = { clearTrack: false }

export const useMe = ({ clearTrack, contractId } = defaultUseMeParams, options = {}) => {
  const profile = getProfile()
  return useQuery(
    ['get-me', clearTrack, contractId],
    context => getUser(context.queryKey?.[1], context.queryKey?.[2]),
    {
      initialData: profile,
      enabled: !!profile,
      select: ({ data }) => data?.content?.user,
      onSuccess: async patient => {
        await options?.onSuccess?.()
        setProfile(patient)
        if (!!patient?.contract) {
          setCookie(null, 'contractId', patient?.contract?.id, {
            maxAge: 24 * 60 * 60, // 1 day
            path: '/'
          })
        }
      },
      ...options
    }
  )
}

export const getTotpStatus = () => api.get('/totp/')

export const postTotp = async verifyCode => {
  const response = await api.post('/totp/', { totp: verifyCode })

  setCookie(null, 'vittude-totp', response.data.content?.vittude_totp, {
    maxAge: 90 * 24 * 60 * 60, // 90 days
    path: '/',
    domain: process.env.NODE_ENV === 'production' && '.vittude.com'
  })

  return response.data
}
