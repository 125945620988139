export const listOfTimeZones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GMT',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Wake',
  'Pacific/Wallis',
  'US/Alaska',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/Hawaii',
  'US/Mountain',
  'US/Pacific',
  'UTC'
]

export const WHERE_DID_YOU_MEET = [
  {
    label: 'Facebook',
    value: 0
  },
  {
    label: 'Instagram',
    value: 1
  },
  {
    label: 'Twitter',
    value: 2
  },
  {
    label: 'LinkedIn',
    value: 3
  },
  {
    label: 'Google',
    value: 4
  },
  {
    label: 'Blog Vittude',
    value: 5
  },
  {
    label: 'Notícias',
    value: 6
  },
  {
    label: 'Jornais',
    value: 7
  },
  {
    label: 'Banner',
    value: 8
  },
  {
    label: 'Divulgação em escola/universidade',
    value: 9
  },
  {
    label: 'Indicação de amigo(a)',
    value: 10
  },
  {
    label: '#VcNaoEstaSozinho',
    value: 11
  },
  {
    label: 'Meu Psicólogo',
    value: 12
  },
  {
    label: 'Vetor Editora',
    value: 13
  },
  {
    label: 'StartPsi',
    value: 14
  },
  {
    label: 'Recompensas Digitais',
    value: 15
  },
  {
    label: 'Banco do Brasil',
    value: 16
  },
  {
    label: 'Convênio',
    value: 18
  },
  {
    label: 'Outros',
    value: 17
  }
]

export const STATES = [
  {
    state: 'AC'
  },
  {
    state: 'AL'
  },
  {
    state: 'AP'
  },
  {
    state: 'AM'
  },
  {
    state: 'BA'
  },
  {
    state: 'CE'
  },
  {
    state: 'DF'
  },
  {
    state: 'ES'
  },
  {
    state: 'GO'
  },
  {
    state: 'MA'
  },
  {
    state: 'MT'
  },
  {
    state: 'MS'
  },
  {
    state: 'MG'
  },
  {
    state: 'PA'
  },
  {
    state: 'PB'
  },
  {
    state: 'PR'
  },
  {
    state: 'PE'
  },
  {
    state: 'PI'
  },
  {
    state: 'RJ'
  },
  {
    state: 'RN'
  },
  {
    state: 'RS'
  },
  {
    state: 'RO'
  },
  {
    state: 'RR'
  },
  {
    state: 'SC'
  },
  {
    state: 'SP'
  },
  {
    state: 'SE'
  },
  {
    state: 'TO'
  }
]

export const COUNTRIES = [
  {
    codigo: '004',
    fone: '0093',
    iso: 'AF',
    iso3: 'AFG',
    nome: 'Afeganistão',
    nomeFormal: 'República Islâmica do Afeganistão'
  },
  {
    codigo: '008',
    fone: '0355',
    iso: 'AL',
    iso3: 'ALB',
    nome: 'Albânia',
    nomeFormal: 'República da Albânia'
  },
  {
    codigo: '012',
    fone: '0213',
    iso: 'DZ',
    iso3: 'DZA',
    nome: 'Algéria',
    nomeFormal: 'República Democrática Popular da Algéria'
  },
  {
    codigo: '016',
    fone: '1684',
    iso: 'AS',
    iso3: 'ASM',
    nome: 'Samoa Americana',
    nomeFormal: 'Território de Samoa Americana'
  },
  {
    codigo: '020',
    fone: '0376',
    iso: 'AD',
    iso3: 'AND',
    nome: 'Andorra',
    nomeFormal: 'Principado Andorra'
  },
  {
    codigo: '024',
    fone: '0244',
    iso: 'AO',
    iso3: 'AGO',
    nome: 'Angola',
    nomeFormal: 'República de Angola'
  },
  {
    codigo: '660',
    fone: '1264',
    iso: 'AI',
    iso3: 'AIA',
    nome: 'Anguilla',
    nomeFormal: 'Anguilla'
  },
  {
    codigo: '010',
    fone: '0672',
    iso: 'AQ',
    iso3: 'ATA',
    nome: 'Antártida',
    nomeFormal: 'Antártida'
  },
  {
    codigo: '028',
    fone: '1268',
    iso: 'AG',
    iso3: 'ATG',
    nome: 'Antigua e Barbuda',
    nomeFormal: 'Antigua e Barbuda'
  },
  {
    codigo: '032',
    fone: '0054',
    iso: 'AR',
    iso3: 'ARG',
    nome: 'Argentina',
    nomeFormal: 'República Argentina'
  },
  {
    codigo: '051',
    fone: '0374',
    iso: 'AM',
    iso3: 'ARM',
    nome: 'Armênia',
    nomeFormal: 'República da Armênia'
  },
  {
    codigo: '533',
    fone: '0297',
    iso: 'AW',
    iso3: 'ABW',
    nome: 'Aruba',
    nomeFormal: 'Aruba'
  },
  {
    codigo: '036',
    fone: '0061',
    iso: 'AU',
    iso3: 'AUS',
    nome: 'Austrália',
    nomeFormal: 'Comunidade da Austrália'
  },
  {
    codigo: '040',
    fone: '0043',
    iso: 'AT',
    iso3: 'AUT',
    nome: 'Áustria',
    nomeFormal: 'República da Áustria'
  },
  {
    codigo: '031',
    fone: '0994',
    iso: 'AZ',
    iso3: 'AZE',
    nome: 'Azerbaijão',
    nomeFormal: 'República do Azerbaijão'
  },
  {
    codigo: '044',
    fone: '1242',
    iso: 'BS',
    iso3: 'BHS',
    nome: 'Bahamas',
    nomeFormal: 'Comunidade de Bahamas'
  },
  {
    codigo: '048',
    fone: '0973',
    iso: 'BH',
    iso3: 'BHR',
    nome: 'Bahrein',
    nomeFormal: 'Reino do Bahrein'
  },
  {
    codigo: '050',
    fone: '0880',
    iso: 'BD',
    iso3: 'BGD',
    nome: 'Bangladesh',
    nomeFormal: 'República Popular de Bangladesh'
  },
  {
    codigo: '052',
    fone: '0246',
    iso: 'BB',
    iso3: 'BRB',
    nome: 'Barbados',
    nomeFormal: 'Barbados'
  },
  {
    codigo: '112',
    fone: '0375',
    iso: 'BY',
    iso3: 'BLR',
    nome: 'Bielorrússia',
    nomeFormal: 'República da Bielorrússia'
  },
  {
    codigo: '056',
    fone: '0032',
    iso: 'BE',
    iso3: 'BEL',
    nome: 'Bélgica',
    nomeFormal: 'Reino da Bélgica'
  },
  {
    codigo: '084',
    fone: '0501',
    iso: 'BZ',
    iso3: 'BLZ',
    nome: 'Belize',
    nomeFormal: 'Belize'
  },
  {
    codigo: '204',
    fone: '0229',
    iso: 'BJ',
    iso3: 'BEN',
    nome: 'Benin',
    nomeFormal: 'República do Benin'
  },
  {
    codigo: '060',
    fone: '1441',
    iso: 'BM',
    iso3: 'BMU',
    nome: 'Bermuda',
    nomeFormal: 'Bermuda'
  },
  {
    codigo: '064',
    fone: '0975',
    iso: 'BT',
    iso3: 'BTN',
    nome: 'Butão',
    nomeFormal: 'Reino do Butão'
  },
  {
    codigo: '068',
    fone: '0591',
    iso: 'BO',
    iso3: 'BOL',
    nome: 'Bolívia',
    nomeFormal: 'Estado Plurinacional da Bolívia'
  },
  {
    codigo: '070',
    fone: '0387',
    iso: 'BA',
    iso3: 'BIH',
    nome: 'Bósnia e Herzegovina',
    nomeFormal: 'Bósnia e Herzegovina'
  },
  {
    codigo: '072',
    fone: '0267',
    iso: 'BW',
    iso3: 'BWA',
    nome: 'Botswana',
    nomeFormal: 'República da Botswana'
  },
  {
    codigo: '074',
    fone: '0047',
    iso: 'BV',
    iso3: 'BVT',
    nome: 'Ilha Bouvet',
    nomeFormal: 'Ilha Bouvet'
  },
  {
    codigo: '076',
    fone: '0055',
    iso: 'BR',
    iso3: 'BRA',
    nome: 'Brasil',
    nomeFormal: 'República Federativa do Brasil'
  },
  {
    codigo: '086',
    fone: '0246',
    iso: 'IO',
    iso3: 'IOT',
    nome: 'Território Britânico do Oceano Índico',
    nomeFormal: 'Território Britânico do Oceano Índico'
  },
  {
    codigo: '096',
    fone: '0673',
    iso: 'BN',
    iso3: 'BRN',
    nome: 'Brunei',
    nomeFormal: 'Estado do Brunei Darussalam'
  },
  {
    codigo: '100',
    fone: '0359',
    iso: 'BG',
    iso3: 'BGR',
    nome: 'Bulgária',
    nomeFormal: 'República da Bulgária'
  },
  {
    codigo: '854',
    fone: '0226',
    iso: 'BF',
    iso3: 'BFA',
    nome: 'Burkina Faso',
    nomeFormal: 'Burkina Faso'
  },
  {
    codigo: '108',
    fone: '0257',
    iso: 'BI',
    iso3: 'BDI',
    nome: 'Burundi',
    nomeFormal: 'República do Burundi'
  },
  {
    codigo: '116',
    fone: '0855',
    iso: 'KH',
    iso3: 'KHM',
    nome: 'Camboja',
    nomeFormal: 'Reino do Camboja'
  },
  {
    codigo: '120',
    fone: '0237',
    iso: 'CM',
    iso3: 'CMR',
    nome: 'Camarões',
    nomeFormal: 'República de Camarões'
  },
  {
    codigo: '124',
    fone: '0001',
    iso: 'CA',
    iso3: 'CAN',
    nome: 'Canadá',
    nomeFormal: 'Canadá'
  },
  {
    codigo: '132',
    fone: '0238',
    iso: 'CV',
    iso3: 'CPV',
    nome: 'Cabo Verde',
    nomeFormal: 'República do Cabo Verde'
  },
  {
    codigo: '136',
    fone: '1345',
    iso: 'KY',
    iso3: 'CYM',
    nome: 'Ilhas Cayman',
    nomeFormal: 'Ilhas Cayman'
  },
  {
    codigo: '140',
    fone: '0236',
    iso: 'CF',
    iso3: 'CAF',
    nome: 'República Centro-Africana',
    nomeFormal: 'República Centro-Africana'
  },
  {
    codigo: '148',
    fone: '0235',
    iso: 'TD',
    iso3: 'TCD',
    nome: 'Chade',
    nomeFormal: 'República do Chade'
  },
  {
    codigo: '152',
    fone: '0056',
    iso: 'CL',
    iso3: 'CHL',
    nome: 'Chile',
    nomeFormal: 'República do Chile'
  },
  {
    codigo: '156',
    fone: '0086',
    iso: 'CN',
    iso3: 'CHN',
    nome: 'China',
    nomeFormal: 'República Popular da China'
  },
  {
    codigo: '162',
    fone: '0061',
    iso: 'CX',
    iso3: 'CXR',
    nome: 'Ilha Christmas',
    nomeFormal: 'Território da Ilha Christmas'
  },
  {
    codigo: '166',
    fone: '0672',
    iso: 'CC',
    iso3: 'CCK',
    nome: 'Ilhas Cocos (Keeling)',
    nomeFormal: 'Território das Ilhas Cocos (Keeling)'
  },
  {
    codigo: '170',
    fone: '0057',
    iso: 'CO',
    iso3: 'COL',
    nome: 'Colômbia',
    nomeFormal: 'República da Colômbia'
  },
  {
    codigo: '174',
    fone: '0269',
    iso: 'KM',
    iso3: 'COM',
    nome: 'Comores',
    nomeFormal: 'União das Comores'
  },
  {
    codigo: '178',
    fone: '0242',
    iso: 'CG',
    iso3: 'COG',
    nome: 'Congo',
    nomeFormal: 'República do Congo'
  },
  {
    codigo: '180',
    fone: '0242',
    iso: 'CD',
    iso3: 'COD',
    nome: 'Congo (DR)',
    nomeFormal: 'República Democrática do Congo'
  },
  {
    codigo: '184',
    fone: '0682',
    iso: 'CK',
    iso3: 'COK',
    nome: 'Ilhas Cook',
    nomeFormal: 'Ilhas Cook'
  },
  {
    codigo: '188',
    fone: '0506',
    iso: 'CR',
    iso3: 'CRI',
    nome: 'Costa Rica',
    nomeFormal: 'República da Costa Rica'
  },
  {
    codigo: '384',
    fone: '0225',
    iso: 'CI',
    iso3: 'CIV',
    nome: 'Costa do Marfim',
    nomeFormal: 'República da Costa do Marfim'
  },
  {
    codigo: '191',
    fone: '0385',
    iso: 'HR',
    iso3: 'HRV',
    nome: 'Croácia',
    nomeFormal: 'República da Croácia'
  },
  {
    codigo: '192',
    fone: '0053',
    iso: 'CU',
    iso3: 'CUB',
    nome: 'Cuba',
    nomeFormal: 'República de Cuba'
  },
  {
    codigo: '196',
    fone: '0357',
    iso: 'CY',
    iso3: 'CYP',
    nome: 'Chipre',
    nomeFormal: 'República do Chipre'
  },
  {
    codigo: '203',
    fone: '0420',
    iso: 'CZ',
    iso3: 'CZE',
    nome: 'República Tcheca',
    nomeFormal: 'República Tcheca'
  },
  {
    codigo: '208',
    fone: '0045',
    iso: 'DK',
    iso3: 'DNK',
    nome: 'Dinamarca',
    nomeFormal: 'Reino da Dinamarca'
  },
  {
    codigo: '262',
    fone: '0253',
    iso: 'DJ',
    iso3: 'DJI',
    nome: 'Djibuti',
    nomeFormal: 'República do Djibuti'
  },
  {
    codigo: '212',
    fone: '1767',
    iso: 'DM',
    iso3: 'DMA',
    nome: 'Dominica',
    nomeFormal: 'Comunidade da Dominica'
  },
  {
    codigo: '214',
    fone: '1809',
    iso: 'DO',
    iso3: 'DOM',
    nome: 'República Dominicana',
    nomeFormal: 'República Dominicana'
  },
  {
    codigo: '218',
    fone: '0593',
    iso: 'EC',
    iso3: 'ECU',
    nome: 'Equador',
    nomeFormal: 'República do Equador'
  },
  {
    codigo: '818',
    fone: '0020',
    iso: 'EG',
    iso3: 'EGY',
    nome: 'Egito',
    nomeFormal: 'República Árabe do Egito'
  },
  {
    codigo: '222',
    fone: '0503',
    iso: 'SV',
    iso3: 'SLV',
    nome: 'El Salvador',
    nomeFormal: 'República El Salvador'
  },
  {
    codigo: '226',
    fone: '0240',
    iso: 'GQ',
    iso3: 'GNQ',
    nome: 'Guiné Equatorial',
    nomeFormal: 'República do Guiné Equatorial'
  },
  {
    codigo: '232',
    fone: '0291',
    iso: 'ER',
    iso3: 'ERI',
    nome: 'Eritreia',
    nomeFormal: 'Estado da Eritreia'
  },
  {
    codigo: '233',
    fone: '0372',
    iso: 'EE',
    iso3: 'EST',
    nome: 'Estônia',
    nomeFormal: 'República da Estônia'
  },
  {
    codigo: '231',
    fone: '0251',
    iso: 'ET',
    iso3: 'ETH',
    nome: 'Etiópia',
    nomeFormal: 'República Democrática Federal da Etiópia'
  },
  {
    codigo: '238',
    fone: '0500',
    iso: 'FK',
    iso3: 'FLK',
    nome: 'Ilhas Malvinas',
    nomeFormal: 'Ilhas Malvinas'
  },
  {
    codigo: '234',
    fone: '0298',
    iso: 'FO',
    iso3: 'FRO',
    nome: 'Ilhas Faroe',
    nomeFormal: 'Ilhas Faroe'
  },
  {
    codigo: '242',
    fone: '0679',
    iso: 'FJ',
    iso3: 'FJI',
    nome: 'Fiji',
    nomeFormal: 'República do Fiji'
  },
  {
    codigo: '246',
    fone: '0358',
    iso: 'FI',
    iso3: 'FIN',
    nome: 'Finlândia',
    nomeFormal: 'República da Finlândia'
  },
  {
    codigo: '250',
    fone: '0033',
    iso: 'FR',
    iso3: 'FRA',
    nome: 'França',
    nomeFormal: 'República Francesa'
  },
  {
    codigo: '254',
    fone: '0594',
    iso: 'GF',
    iso3: 'GUF',
    nome: 'Guiana Francesa',
    nomeFormal: 'Guiana Francesa'
  },
  {
    codigo: '258',
    fone: '0689',
    iso: 'PF',
    iso3: 'PYF',
    nome: 'Polinésia Francesa',
    nomeFormal: 'Polinésia Francesa'
  },
  {
    codigo: '260',
    fone: '0033',
    iso: 'TF',
    iso3: 'ATF',
    nome: 'Terras Austrais e Antárticas Francesas',
    nomeFormal: 'Território das Terras Austrais e Antárticas Francesas'
  },
  {
    codigo: '266',
    fone: '0241',
    iso: 'GA',
    iso3: 'GAB',
    nome: 'Gabão',
    nomeFormal: 'República Gabonesa'
  },
  {
    codigo: '270',
    fone: '0220',
    iso: 'GM',
    iso3: 'GMB',
    nome: 'Gâmbia',
    nomeFormal: 'República da Gâmbia'
  },
  {
    codigo: '268',
    fone: '0995',
    iso: 'GE',
    iso3: 'GEO',
    nome: 'Geórgia',
    nomeFormal: 'Geórgia'
  },
  {
    codigo: '276',
    fone: '0049',
    iso: 'DE',
    iso3: 'DEU',
    nome: 'Alemanha',
    nomeFormal: 'República Federal da Alemanha'
  },
  {
    codigo: '288',
    fone: '0233',
    iso: 'GH',
    iso3: 'GHA',
    nome: 'Gana',
    nomeFormal: 'Repúblia de Gana'
  },
  {
    codigo: '292',
    fone: '0350',
    iso: 'GI',
    iso3: 'GIB',
    nome: 'Gibraltar',
    nomeFormal: 'Gibraltar'
  },
  {
    codigo: '300',
    fone: '0030',
    iso: 'GR',
    iso3: 'GRC',
    nome: 'Grécia',
    nomeFormal: 'República Helênica'
  },
  {
    codigo: '304',
    fone: '0299',
    iso: 'GL',
    iso3: 'GRL',
    nome: 'Groelândia',
    nomeFormal: 'Groelândia'
  },
  {
    codigo: '308',
    fone: '1473',
    iso: 'GD',
    iso3: 'GRD',
    nome: 'Granada',
    nomeFormal: 'Granada'
  },
  {
    codigo: '312',
    fone: '0590',
    iso: 'GP',
    iso3: 'GLP',
    nome: 'Guadalupe',
    nomeFormal: 'Guadalupe'
  },
  {
    codigo: '316',
    fone: '1671',
    iso: 'GU',
    iso3: 'GUM',
    nome: 'Guão',
    nomeFormal: 'Território do Guão'
  },
  {
    codigo: '320',
    fone: '0502',
    iso: 'GT',
    iso3: 'GTM',
    nome: 'Guatemala',
    nomeFormal: 'República da Guatemala'
  },
  {
    codigo: '324',
    fone: '0224',
    iso: 'GN',
    iso3: 'GIN',
    nome: 'Guiné',
    nomeFormal: 'República do Guiné'
  },
  {
    codigo: '624',
    fone: '0245',
    iso: 'GW',
    iso3: 'GNB',
    nome: 'Guiné-Bissau',
    nomeFormal: 'República da Guiné-Bissau'
  },
  {
    codigo: '328',
    fone: '0592',
    iso: 'GY',
    iso3: 'GUY',
    nome: 'Guiana',
    nomeFormal: 'República Cooperativa da Guiana'
  },
  {
    codigo: '332',
    fone: '0509',
    iso: 'HT',
    iso3: 'HTI',
    nome: 'Haiti',
    nomeFormal: 'República do Haiti'
  },
  {
    codigo: '334',
    fone: '0672',
    iso: 'HM',
    iso3: 'HMD',
    nome: 'Ilhas Heard e McDonald',
    nomeFormal: 'Território das Ilhas Heard e McDonald'
  },
  {
    codigo: '336',
    fone: '0039',
    iso: 'VA',
    iso3: 'VAT',
    nome: 'Vaticano',
    nomeFormal: 'Estado da Cidade do Vaticano'
  },
  {
    codigo: '340',
    fone: '0504',
    iso: 'HN',
    iso3: 'HND',
    nome: 'Honduras',
    nomeFormal: 'República de Honduras'
  },
  {
    codigo: '344',
    fone: '0852',
    iso: 'HK',
    iso3: 'HKG',
    nome: 'Hong Kong',
    nomeFormal: 'Região Administrativa Especial de Hong Kong da República Popular da China'
  },
  {
    codigo: '348',
    fone: '0036',
    iso: 'HU',
    iso3: 'HUN',
    nome: 'Hungria',
    nomeFormal: 'Hungria'
  },
  {
    codigo: '352',
    fone: '0354',
    iso: 'IS',
    iso3: 'ISL',
    nome: 'Islândia',
    nomeFormal: 'Islândia'
  },
  {
    codigo: '356',
    fone: '0091',
    iso: 'IN',
    iso3: 'IND',
    nome: 'Índia',
    nomeFormal: 'República da Índia'
  },
  {
    codigo: '360',
    fone: '0062',
    iso: 'ID',
    iso3: 'IDN',
    nome: 'Indonésia',
    nomeFormal: 'República da Indonésia'
  },
  {
    codigo: '364',
    fone: '0098',
    iso: 'IR',
    iso3: 'IRN',
    nome: 'Iran',
    nomeFormal: 'República Islâmica do Iran'
  },
  {
    codigo: '368',
    fone: '0964',
    iso: 'IQ',
    iso3: 'IRQ',
    nome: 'Iraque',
    nomeFormal: 'República do Iraque'
  },
  {
    codigo: '372',
    fone: '0353',
    iso: 'IE',
    iso3: 'IRL',
    nome: 'Irlanda',
    nomeFormal: 'Irlanda'
  },
  {
    codigo: '376',
    fone: '0972',
    iso: 'IL',
    iso3: 'ISR',
    nome: 'Israel',
    nomeFormal: 'Estado de Israel'
  },
  {
    codigo: '380',
    fone: '0039',
    iso: 'IT',
    iso3: 'ITA',
    nome: 'Italia',
    nomeFormal: 'República Italiana'
  },
  {
    codigo: '388',
    fone: '1876',
    iso: 'JM',
    iso3: 'JAM',
    nome: 'Jamaica',
    nomeFormal: 'Jamaica'
  },
  {
    codigo: '392',
    fone: '0081',
    iso: 'JP',
    iso3: 'JPN',
    nome: 'Japão',
    nomeFormal: 'Japão'
  },
  {
    codigo: '400',
    fone: '0962',
    iso: 'JO',
    iso3: 'JOR',
    nome: 'Jornânia',
    nomeFormal: 'Reino Hachemita da Jordânia'
  },
  {
    codigo: '398',
    fone: '0007',
    iso: 'KZ',
    iso3: 'KAZ',
    nome: 'Cazaquistão',
    nomeFormal: 'República do Cazaquistão'
  },
  {
    codigo: '404',
    fone: '0254',
    iso: 'KE',
    iso3: 'KEN',
    nome: 'Quênia',
    nomeFormal: 'República do Quênia'
  },
  {
    codigo: '296',
    fone: '0686',
    iso: 'KI',
    iso3: 'KIR',
    nome: 'Kiribati',
    nomeFormal: 'República do Kiribati'
  },
  {
    codigo: '408',
    fone: '0850',
    iso: 'KP',
    iso3: 'PRK',
    nome: 'Coreia do Norte',
    nomeFormal: 'República Democrática Popular da Coreia'
  },
  {
    codigo: '410',
    fone: '0082',
    iso: 'KR',
    iso3: 'KOR',
    nome: 'Coreia do Sul',
    nomeFormal: 'República da Coreia'
  },
  {
    codigo: '414',
    fone: '0965',
    iso: 'KW',
    iso3: 'KWT',
    nome: 'Kuwait',
    nomeFormal: 'Estado do Kuwait'
  },
  {
    codigo: '417',
    fone: '0996',
    iso: 'KG',
    iso3: 'KGZ',
    nome: 'Quirguistão',
    nomeFormal: 'República Quirguiz'
  },
  {
    codigo: '418',
    fone: '0856',
    iso: 'LA',
    iso3: 'LAO',
    nome: 'Laos',
    nomeFormal: 'República Democrática Popular Lau'
  },
  {
    codigo: '428',
    fone: '0371',
    iso: 'LV',
    iso3: 'LVA',
    nome: 'Letônia',
    nomeFormal: 'República da Letônia'
  },
  {
    codigo: '422',
    fone: '0961',
    iso: 'LB',
    iso3: 'LBN',
    nome: 'Líbano',
    nomeFormal: 'República Libanesa'
  },
  {
    codigo: '426',
    fone: '0266',
    iso: 'LS',
    iso3: 'LSO',
    nome: 'Lesoto',
    nomeFormal: 'Reino do Lesoto'
  },
  {
    codigo: '430',
    fone: '0231',
    iso: 'LR',
    iso3: 'LBR',
    nome: 'Libéria',
    nomeFormal: 'República da Libéria'
  },
  {
    codigo: '434',
    fone: '0218',
    iso: 'LY',
    iso3: 'LBY',
    nome: 'Líbia',
    nomeFormal: 'Líbia'
  },
  {
    codigo: '438',
    fone: '0423',
    iso: 'LI',
    iso3: 'LIE',
    nome: 'Liechtenstein',
    nomeFormal: 'Principado de Liechtenstein'
  },
  {
    codigo: '440',
    fone: '0370',
    iso: 'LT',
    iso3: 'LTU',
    nome: 'Lituânia',
    nomeFormal: 'República da Lituânia'
  },
  {
    codigo: '442',
    fone: '0352',
    iso: 'LU',
    iso3: 'LUX',
    nome: 'Luxemburgo',
    nomeFormal: 'Grão-Ducado do Luxemburgo'
  },
  {
    codigo: '446',
    fone: '0853',
    iso: 'MO',
    iso3: 'MAC',
    nome: 'Macao',
    nomeFormal: 'Macao'
  },
  {
    codigo: '807',
    fone: '0389',
    iso: 'MK',
    iso3: 'MKD',
    nome: 'Macedônia',
    nomeFormal: 'República da Macedônia'
  },
  {
    codigo: '450',
    fone: '0261',
    iso: 'MG',
    iso3: 'MDG',
    nome: 'Madagascar',
    nomeFormal: 'República de Madagascar'
  },
  {
    codigo: '454',
    fone: '0265',
    iso: 'MW',
    iso3: 'MWI',
    nome: 'Malawi',
    nomeFormal: 'República de Malawi'
  },
  {
    codigo: '458',
    fone: '0060',
    iso: 'MY',
    iso3: 'MYS',
    nome: 'Malásia',
    nomeFormal: 'Malásia'
  },
  {
    codigo: '462',
    fone: '0960',
    iso: 'MV',
    iso3: 'MDV',
    nome: 'Maldivas',
    nomeFormal: 'Reública de Maldivas'
  },
  {
    codigo: '466',
    fone: '0223',
    iso: 'ML',
    iso3: 'MLI',
    nome: 'Mali',
    nomeFormal: 'República do Mali'
  },
  {
    codigo: '470',
    fone: '0356',
    iso: 'MT',
    iso3: 'MLT',
    nome: 'Malta',
    nomeFormal: 'República de Malta'
  },
  {
    codigo: '584',
    fone: '0692',
    iso: 'MH',
    iso3: 'MHL',
    nome: 'Ilhas Marshall',
    nomeFormal: 'República das Ilhas Marshall'
  },
  {
    codigo: '474',
    fone: '0596',
    iso: 'MQ',
    iso3: 'MTQ',
    nome: 'Martinica',
    nomeFormal: 'Martinica'
  },
  {
    codigo: '478',
    fone: '0222',
    iso: 'MR',
    iso3: 'MRT',
    nome: 'Mauritânia',
    nomeFormal: 'República Islâmica da Mauritânia'
  },
  {
    codigo: '480',
    fone: '0230',
    iso: 'MU',
    iso3: 'MUS',
    nome: 'Maurício',
    nomeFormal: 'República de Maurício'
  },
  {
    codigo: '175',
    fone: '0269',
    iso: 'YT',
    iso3: 'MYT',
    nome: 'Mayotte',
    nomeFormal: 'Departamento de Mayotte'
  },
  {
    codigo: '484',
    fone: '0052',
    iso: 'MX',
    iso3: 'MEX',
    nome: 'México',
    nomeFormal: 'Estados Unidos Mexicanos'
  },
  {
    codigo: '583',
    fone: '0691',
    iso: 'FM',
    iso3: 'FSM',
    nome: 'Micronésia',
    nomeFormal: 'Estados Federados da Micronesia'
  },
  {
    codigo: '498',
    fone: '0373',
    iso: 'MD',
    iso3: 'MDA',
    nome: 'Moldova',
    nomeFormal: 'República de Moldova'
  },
  {
    codigo: '492',
    fone: '0377',
    iso: 'MC',
    iso3: 'MCO',
    nome: 'Mônaco',
    nomeFormal: 'Principado de Mônaco'
  },
  {
    codigo: '496',
    fone: '0976',
    iso: 'MN',
    iso3: 'MNG',
    nome: 'Mongólia',
    nomeFormal: 'Mongólia'
  },
  {
    codigo: '500',
    fone: '1664',
    iso: 'MS',
    iso3: 'MSR',
    nome: 'Montserrat',
    nomeFormal: 'Montserrat'
  },
  {
    codigo: '504',
    fone: '0212',
    iso: 'MA',
    iso3: 'MAR',
    nome: 'Marrocos',
    nomeFormal: 'Reino de Marrocos'
  },
  {
    codigo: '508',
    fone: '0258',
    iso: 'MZ',
    iso3: 'MOZ',
    nome: 'Moçambique',
    nomeFormal: 'República de Moçambique'
  },
  {
    codigo: '104',
    fone: '0095',
    iso: 'MM',
    iso3: 'MMR',
    nome: 'Birmânia',
    nomeFormal: 'República da União de Myanmar'
  },
  {
    codigo: '516',
    fone: '0264',
    iso: 'NA',
    iso3: 'NAM',
    nome: 'Namíbia',
    nomeFormal: 'República da Namíbia'
  },
  {
    codigo: '520',
    fone: '0674',
    iso: 'NR',
    iso3: 'NRU',
    nome: 'Nauru',
    nomeFormal: 'República de Nauru'
  },
  {
    codigo: '524',
    fone: '0977',
    iso: 'NP',
    iso3: 'NPL',
    nome: 'Nepal',
    nomeFormal: 'República Democrática Federativa do Nepal'
  },
  {
    codigo: '528',
    fone: '0031',
    iso: 'NL',
    iso3: 'NLD',
    nome: 'Holanda',
    nomeFormal: 'Holanda'
  },
  {
    codigo: '530',
    fone: '0599',
    iso: 'AN',
    iso3: 'ANT',
    nome: 'Antilhas Holandesas',
    nomeFormal: 'Antilhas Holandesas'
  },
  {
    codigo: '540',
    fone: '0687',
    iso: 'NC',
    iso3: 'NCL',
    nome: 'Nova Caledônia',
    nomeFormal: 'Nova Caledônia'
  },
  {
    codigo: '554',
    fone: '0064',
    iso: 'NZ',
    iso3: 'NZL',
    nome: 'Nova Zelândia',
    nomeFormal: 'Nova Zelândia'
  },
  {
    codigo: '558',
    fone: '0505',
    iso: 'NI',
    iso3: 'NIC',
    nome: 'Nicarágua',
    nomeFormal: 'República da Nicarágua'
  },
  {
    codigo: '562',
    fone: '0227',
    iso: 'NE',
    iso3: 'NER',
    nome: 'Niger',
    nomeFormal: 'República do Niger'
  },
  {
    codigo: '566',
    fone: '0234',
    iso: 'NG',
    iso3: 'NGA',
    nome: 'Nigéria',
    nomeFormal: 'República Federativa da Nigéria'
  },
  {
    codigo: '570',
    fone: '0683',
    iso: 'NU',
    iso3: 'NIU',
    nome: 'Niue',
    nomeFormal: 'Niue'
  },
  {
    codigo: '574',
    fone: '0672',
    iso: 'NF',
    iso3: 'NFK',
    nome: 'Ilha Norfolk',
    nomeFormal: 'Território da Ilha Norfolk'
  },
  {
    codigo: '580',
    fone: '1670',
    iso: 'MP',
    iso3: 'MNP',
    nome: 'Ilhas Marianas do Norte',
    nomeFormal: 'Comunidade das Ilhas Marianas do Norte'
  },
  {
    codigo: '578',
    fone: '0047',
    iso: 'NO',
    iso3: 'NOR',
    nome: 'Noruega',
    nomeFormal: 'Reino da Noruega'
  },
  {
    codigo: '512',
    fone: '0968',
    iso: 'OM',
    iso3: 'OMN',
    nome: 'Omã',
    nomeFormal: 'Sultanato de Omã'
  },
  {
    codigo: '586',
    fone: '0092',
    iso: 'PK',
    iso3: 'PAK',
    nome: 'Paquistão',
    nomeFormal: 'República Islâmica do Paquistão'
  },
  {
    codigo: '585',
    fone: '0680',
    iso: 'PW',
    iso3: 'PLW',
    nome: 'Palau',
    nomeFormal: 'República de Palau'
  },
  {
    codigo: '275',
    fone: '0970',
    iso: 'PS',
    iso3: 'PSE',
    nome: 'Palestina',
    nomeFormal: 'Estado da Palestina'
  },
  {
    codigo: '591',
    fone: '0507',
    iso: 'PA',
    iso3: 'PAN',
    nome: 'Panamá',
    nomeFormal: 'República do Panamá'
  },
  {
    codigo: '598',
    fone: '0675',
    iso: 'PG',
    iso3: 'PNG',
    nome: 'Papua-Nova Guiné',
    nomeFormal: 'Estado Independente da Papua-Nova Guiné'
  },
  {
    codigo: '600',
    fone: '0595',
    iso: 'PY',
    iso3: 'PRY',
    nome: 'Paraguai',
    nomeFormal: 'República do Paraguai'
  },
  {
    codigo: '604',
    fone: '0051',
    iso: 'PE',
    iso3: 'PER',
    nome: 'Peru',
    nomeFormal: 'República do Peru'
  },
  {
    codigo: '608',
    fone: '0063',
    iso: 'PH',
    iso3: 'PHL',
    nome: 'Filipinas',
    nomeFormal: 'República das Filipinas'
  },
  {
    codigo: '612',
    fone: '0672',
    iso: 'PN',
    iso3: 'PCN',
    nome: 'Ilhas Picárnia',
    nomeFormal: 'Ilhas Picárnia'
  },
  {
    codigo: '616',
    fone: '0048',
    iso: 'PL',
    iso3: 'POL',
    nome: 'Polônia',
    nomeFormal: 'República da Polônia'
  },
  {
    codigo: '620',
    fone: '0351',
    iso: 'PT',
    iso3: 'PRT',
    nome: 'Portugal',
    nomeFormal: 'República Portuguesa'
  },
  {
    codigo: '630',
    fone: '1787',
    iso: 'PR',
    iso3: 'PRI',
    nome: 'Porto Rico',
    nomeFormal: 'Comunidade do Porto Rico'
  },
  {
    codigo: '634',
    fone: '0974',
    iso: 'QA',
    iso3: 'QAT',
    nome: 'Catar',
    nomeFormal: 'Estado do Catar'
  },
  {
    codigo: '638',
    fone: '0262',
    iso: 'RE',
    iso3: 'REU',
    nome: 'Reunião',
    nomeFormal: 'Polônia'
  },
  {
    codigo: '642',
    fone: '0040',
    iso: 'RO',
    iso3: 'ROM',
    nome: 'Romênia',
    nomeFormal: 'Romênia'
  },
  {
    codigo: '643',
    fone: '0070',
    iso: 'RU',
    iso3: 'RUS',
    nome: 'Rússia',
    nomeFormal: 'Federação Russa'
  },
  {
    codigo: '646',
    fone: '0250',
    iso: 'RW',
    iso3: 'RWA',
    nome: 'Ruanda',
    nomeFormal: 'República da Ruanda'
  },
  {
    codigo: '654',
    fone: '0290',
    iso: 'SH',
    iso3: 'SHN',
    nome: 'Santa Helena',
    nomeFormal: 'Saint Helena'
  },
  {
    codigo: '659',
    fone: '1869',
    iso: 'KN',
    iso3: 'KNA',
    nome: 'São Cristóvão',
    nomeFormal: 'São Cristóvão'
  },
  {
    codigo: '662',
    fone: '1758',
    iso: 'LC',
    iso3: 'LCA',
    nome: 'Santa Lúcia',
    nomeFormal: 'Santa Lúcia'
  },
  {
    codigo: '666',
    fone: '0508',
    iso: 'PM',
    iso3: 'SPM',
    nome: 'São Pedro e Miquelon',
    nomeFormal: 'Coletividade Territorial de São Pedro e Miquelon'
  },
  {
    codigo: '670',
    fone: '1784',
    iso: 'VC',
    iso3: 'VCT',
    nome: 'São Vicente e Granadinas',
    nomeFormal: 'São Vicente e Granadinas'
  },
  {
    codigo: '882',
    fone: '0684',
    iso: 'WS',
    iso3: 'WSM',
    nome: 'Samoa',
    nomeFormal: 'Estado Independente de Samoa'
  },
  {
    codigo: '674',
    fone: '0378',
    iso: 'SM',
    iso3: 'SMR',
    nome: 'São Marino',
    nomeFormal: 'República de São Marino'
  },
  {
    codigo: '678',
    fone: '0239',
    iso: 'ST',
    iso3: 'STP',
    nome: 'Sao Tomé e Príncipe',
    nomeFormal: 'República Democrática de Sao Tomé e Príncipe'
  },
  {
    codigo: '682',
    fone: '0966',
    iso: 'SA',
    iso3: 'SAU',
    nome: 'Arábia Saudita',
    nomeFormal: 'Reino da Arábia Saudita'
  },
  {
    codigo: '686',
    fone: '0221',
    iso: 'SN',
    iso3: 'SEN',
    nome: 'Senegal',
    nomeFormal: 'República do Senegal'
  },
  {
    codigo: '688',
    fone: '0381',
    iso: 'CS',
    iso3: 'SRB',
    nome: 'Sérvia e Montenegro',
    nomeFormal: 'União Estatal de Sérvia e Montenegro'
  },
  {
    codigo: '690',
    fone: '0248',
    iso: 'SC',
    iso3: 'SYC',
    nome: 'Seicheles',
    nomeFormal: 'República das Seicheles'
  },
  {
    codigo: '694',
    fone: '0232',
    iso: 'SL',
    iso3: 'SLE',
    nome: 'República da Serra Leoa',
    nomeFormal: 'República da Serra Leoa'
  },
  {
    codigo: '702',
    fone: '0065',
    iso: 'SG',
    iso3: 'SGP',
    nome: 'Singapura',
    nomeFormal: 'República da Singapura'
  },
  {
    codigo: '703',
    fone: '0421',
    iso: 'SK',
    iso3: 'SVK',
    nome: 'Eslováquia',
    nomeFormal: 'República Eslovaca'
  },
  {
    codigo: '705',
    fone: '0386',
    iso: 'SI',
    iso3: 'SVN',
    nome: 'Eslovênia',
    nomeFormal: 'República da Eslovênia'
  },
  {
    codigo: '090',
    fone: '0677',
    iso: 'SB',
    iso3: 'SLB',
    nome: 'Ilhas Salomão',
    nomeFormal: 'Ilhas Salomão'
  },
  {
    codigo: '706',
    fone: '0252',
    iso: 'SO',
    iso3: 'SOM',
    nome: 'Somália',
    nomeFormal: 'República da Somália'
  },
  {
    codigo: '710',
    fone: '0027',
    iso: 'ZA',
    iso3: 'ZAF',
    nome: 'África do Sul',
    nomeFormal: 'República da África do Sul'
  },
  {
    codigo: '239',
    fone: '0500',
    iso: 'GS',
    iso3: 'SGS',
    nome: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    nomeFormal: 'Ilhas Geórgia do Sul e Sandwich do Sul'
  },
  {
    codigo: '724',
    fone: '0034',
    iso: 'ES',
    iso3: 'ESP',
    nome: 'Espanha',
    nomeFormal: 'Reino da Espanha'
  },
  {
    codigo: '144',
    fone: '0094',
    iso: 'LK',
    iso3: 'LKA',
    nome: 'Sri Lanka',
    nomeFormal: 'República Democrática Socialista do Sri Lanka'
  },
  {
    codigo: '736',
    fone: '0249',
    iso: 'SD',
    iso3: 'SDN',
    nome: 'Sudão',
    nomeFormal: 'República do Sudão'
  },
  {
    codigo: '740',
    fone: '0597',
    iso: 'SR',
    iso3: 'SUR',
    nome: 'Suriname',
    nomeFormal: 'República do Suriname'
  },
  {
    codigo: '744',
    fone: '0047',
    iso: 'SJ',
    iso3: 'SJM',
    nome: 'Esvalbarde',
    nomeFormal: 'Esvalbarde'
  },
  {
    codigo: '748',
    fone: '0268',
    iso: 'SZ',
    iso3: 'SWZ',
    nome: 'Suazilândia',
    nomeFormal: 'Reino da Suazilândia'
  },
  {
    codigo: '752',
    fone: '0046',
    iso: 'SE',
    iso3: 'SWE',
    nome: 'Suécia',
    nomeFormal: 'Reino da Suécia'
  },
  {
    codigo: '756',
    fone: '0041',
    iso: 'CH',
    iso3: 'CHE',
    nome: 'Suiça',
    nomeFormal: 'Confederação Suiça'
  },
  {
    codigo: '760',
    fone: '0963',
    iso: 'SY',
    iso3: 'SYR',
    nome: 'Síria',
    nomeFormal: 'República Árabe Síria'
  },
  {
    codigo: '158',
    fone: '0886',
    iso: 'TW',
    iso3: 'TWN',
    nome: 'Taiwan',
    nomeFormal: 'Taiwan'
  },
  {
    codigo: '762',
    fone: '0992',
    iso: 'TJ',
    iso3: 'TJK',
    nome: 'Tajiquistão',
    nomeFormal: 'República do Tajiquistão'
  },
  {
    codigo: '834',
    fone: '0255',
    iso: 'TZ',
    iso3: 'TZA',
    nome: 'Tanzânia',
    nomeFormal: 'República Unida da Tanzânia'
  },
  {
    codigo: '764',
    fone: '0066',
    iso: 'TH',
    iso3: 'THA',
    nome: 'Tailândia',
    nomeFormal: 'Reino da Tailândia'
  },
  {
    codigo: '626',
    fone: '0670',
    iso: 'TL',
    iso3: 'TLS',
    nome: 'Timor-Leste',
    nomeFormal: 'República Democrática de Timor-Leste'
  },
  {
    codigo: '768',
    fone: '0228',
    iso: 'TG',
    iso3: 'TGO',
    nome: 'Togo',
    nomeFormal: 'República Togolesa'
  },
  {
    codigo: '772',
    fone: '0690',
    iso: 'TK',
    iso3: 'TKL',
    nome: 'Toquelau',
    nomeFormal: 'Toquelau'
  },
  {
    codigo: '776',
    fone: '0676',
    iso: 'TO',
    iso3: 'TON',
    nome: 'Tonga',
    nomeFormal: 'Reino de Tonga'
  },
  {
    codigo: '780',
    fone: '1868',
    iso: 'TT',
    iso3: 'TTO',
    nome: 'Trinidad e Tobago',
    nomeFormal: 'República da Trinidad e Tobago'
  },
  {
    codigo: '788',
    fone: '0216',
    iso: 'TN',
    iso3: 'TUN',
    nome: 'Tunísia',
    nomeFormal: 'República da Tunísia'
  },
  {
    codigo: '792',
    fone: '0090',
    iso: 'TR',
    iso3: 'TUR',
    nome: 'Turquia',
    nomeFormal: 'República da Turquia'
  },
  {
    codigo: '795',
    fone: '7370',
    iso: 'TM',
    iso3: 'TKM',
    nome: 'Turcomenistão',
    nomeFormal: 'Turcomenistão'
  },
  {
    codigo: '796',
    fone: '1649',
    iso: 'TC',
    iso3: 'TCA',
    nome: 'Ilhas Turks e Caicos',
    nomeFormal: 'Ilhas Turks e Caicos'
  },
  {
    codigo: '798',
    fone: '0688',
    iso: 'TV',
    iso3: 'TUV',
    nome: 'Tuvalu',
    nomeFormal: 'Tuvalu'
  },
  {
    codigo: '800',
    fone: '0256',
    iso: 'UG',
    iso3: 'UGA',
    nome: 'Uganda',
    nomeFormal: 'República de Uganda'
  },
  {
    codigo: '804',
    fone: '0380',
    iso: 'UA',
    iso3: 'UKR',
    nome: 'Ucrânia',
    nomeFormal: 'Ucrânia'
  },
  {
    codigo: '784',
    fone: '0971',
    iso: 'AE',
    iso3: 'ARE',
    nome: 'Emirados Árabes',
    nomeFormal: 'Emirados Árabes Unidos'
  },
  {
    codigo: '826',
    fone: '0044',
    iso: 'GB',
    iso3: 'GBR',
    nome: 'Reino Unido',
    nomeFormal: 'Reino Unido da Grã-Bretanha e Irlanda do Norte'
  },
  {
    codigo: '840',
    fone: '0001',
    iso: 'US',
    iso3: 'USA',
    nome: 'Estados Unidos',
    nomeFormal: 'Estados Unidos da América'
  },
  {
    codigo: '581',
    fone: '0001',
    iso: 'UM',
    iso3: 'UMI',
    nome: 'Ilhas Menores Distantes dos Estados Unidos',
    nomeFormal: 'Ilhas Menores Distantes dos Estados Unidos'
  },
  {
    codigo: '858',
    fone: '0598',
    iso: 'UY',
    iso3: 'URY',
    nome: 'Uruguai',
    nomeFormal: 'República Oriental do Uruguai'
  },
  {
    codigo: '860',
    fone: '0998',
    iso: 'UZ',
    iso3: 'UZB',
    nome: 'Uzbequistão',
    nomeFormal: 'República do Uzbequistão'
  },
  {
    codigo: '548',
    fone: '0678',
    iso: 'VU',
    iso3: 'VUT',
    nome: 'Vanuatu',
    nomeFormal: 'República de Vanuatu'
  },
  {
    codigo: '862',
    fone: '0058',
    iso: 'VE',
    iso3: 'VEN',
    nome: 'Venezuela',
    nomeFormal: 'República Bolivariana da Venezuela'
  },
  {
    codigo: '704',
    fone: '0084',
    iso: 'VN',
    iso3: 'VNM',
    nome: 'Vietnam',
    nomeFormal: 'República Socialista do Vietnam'
  },
  {
    codigo: '092',
    fone: '1284',
    iso: 'VG',
    iso3: 'VGB',
    nome: 'Ilhas Virgens Inglesas',
    nomeFormal: 'Ilhas Virgens'
  },
  {
    codigo: '850',
    fone: '1340',
    iso: 'VI',
    iso3: 'VIR',
    nome: 'Ilhas Virgens (USA)',
    nomeFormal: 'Ilhas Virgens dos Estados Unidos'
  },
  {
    codigo: '876',
    fone: '0681',
    iso: 'WF',
    iso3: 'WLF',
    nome: 'Wallis e Futuna',
    nomeFormal: 'Wallis e Futuna'
  },
  {
    codigo: '732',
    fone: '0212',
    iso: 'EH',
    iso3: 'ESH',
    nome: 'Saara Ocidental',
    nomeFormal: 'Saara Ocidental'
  },
  {
    codigo: '887',
    fone: '0967',
    iso: 'YE',
    iso3: 'YEM',
    nome: 'Iêmen',
    nomeFormal: 'República do Iêmen'
  },
  {
    codigo: '894',
    fone: '0260',
    iso: 'ZM',
    iso3: 'ZMB',
    nome: 'Zâmbia',
    nomeFormal: 'República do Zâmbia'
  },
  {
    codigo: '716',
    fone: '0263',
    iso: 'ZW',
    iso3: 'ZWE',
    nome: 'Zimbábue',
    nomeFormal: 'República do Zimbábue'
  }
]

export const SEO_LINKS = [
  {
    slug: 'caxias-do-sul',
    neighborhoods: [
      {
        link:
          '/psicologos/caxias-do-sul/exposicao?lat=-29.1731093&long=-51.1755325&address=Exposi%C3%A7%C3%A3o-Caxias+do+Sul-RS',
        slug_city: 'caxias-do-sul',
        city: 'Caxias do Sul',
        slug_neighborhood: 'exposicao',
        neighborhood: 'Exposição',
        state: 'RS'
      },
      {
        link:
          '/psicologos/caxias-do-sul/universitario?lat=-29.151885&long=-51.1781472&address=Universit%C3%A1rio-Caxias+do+Sul-RS',
        slug_city: 'caxias-do-sul',
        city: 'Caxias do Sul',
        slug_neighborhood: 'universitario',
        neighborhood: 'Universitário',
        state: 'RS'
      }
    ],
    name: 'Caxias do Sul'
  },
  {
    slug: 'cotia',
    neighborhoods: [
      {
        link:
          '/psicologos/cotia/sao-paulo-ii?lat=-23.5732202&long=-46.8756384&address=S%C3%A3o+Paulo+II-Cotia-SP',
        slug_city: 'cotia',
        city: 'Cotia',
        slug_neighborhood: 'sao-paulo-ii',
        neighborhood: 'São Paulo II',
        state: 'SP'
      }
    ],
    name: 'Cotia'
  },
  {
    slug: 'florianopolis',
    neighborhoods: [
      {
        link:
          '/psicologos/florianopolis/centro?lat=-27.5922687&long=-48.5490266&address=Centro-Florian%C3%B3polis-SC',
        slug_city: 'florianopolis',
        city: 'Florianópolis',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SC'
      },
      {
        link:
          '/psicologos/florianopolis/corrego-grande?lat=-27.593609&long=-48.5027406&address=C%C3%B3rrego+Grande-Florian%C3%B3polis-SC',
        slug_city: 'florianopolis',
        city: 'Florianópolis',
        slug_neighborhood: 'corrego-grande',
        neighborhood: 'Córrego Grande',
        state: 'SC'
      },
      {
        link:
          '/psicologos/florianopolis/trindade?lat=-27.594124&long=-48.5262256&address=Trindade-Florian%C3%B3polis-SC',
        slug_city: 'florianopolis',
        city: 'Florianópolis',
        slug_neighborhood: 'trindade',
        neighborhood: 'Trindade',
        state: 'SC'
      }
    ],
    name: 'Florianópolis'
  },
  {
    slug: 'pocos-de-caldas',
    neighborhoods: [
      {
        link:
          '/psicologos/pocos-de-caldas/centro?lat=-21.7806158&long=-46.5668298&address=Centro-Po%C3%A7os+de+Caldas-MG',
        slug_city: 'pocos-de-caldas',
        city: 'Poços de Caldas',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'MG'
      }
    ],
    name: 'Poços de Caldas'
  },
  {
    slug: 'fortaleza',
    neighborhoods: [
      {
        link:
          '/psicologos/fortaleza/aldeota?lat=-3.7357094&long=-38.5069693&address=Aldeota-Fortaleza-CE',
        slug_city: 'fortaleza',
        city: 'Fortaleza',
        slug_neighborhood: 'aldeota',
        neighborhood: 'Aldeota',
        state: 'CE'
      },
      {
        link:
          '/psicologos/fortaleza/cidade-dos-funcionarios?lat=-3.7971643&long=-38.4999641&address=Cidade+dos+Funcion%C3%A1rios-Fortaleza-CE',
        slug_city: 'fortaleza',
        city: 'Fortaleza',
        slug_neighborhood: 'cidade-dos-funcionarios',
        neighborhood: 'Cidade dos Funcionários',
        state: 'CE'
      },
      {
        link:
          '/psicologos/fortaleza/dionisio-torres?lat=-3.7492971&long=-38.5025992&address=Dionisio+Torres-Fortaleza-CE',
        slug_city: 'fortaleza',
        city: 'Fortaleza',
        slug_neighborhood: 'dionisio-torres',
        neighborhood: 'Dionisio Torres',
        state: 'CE'
      }
    ],
    name: 'Fortaleza'
  },
  {
    slug: 'londrina',
    neighborhoods: [
      {
        link:
          '/psicologos/londrina/centro?lat=-23.3147325&long=-51.1611537&address=Centro-Londrina-PR',
        slug_city: 'londrina',
        city: 'Londrina',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'PR'
      }
    ],
    name: 'Londrina'
  },
  {
    slug: 'santos',
    neighborhoods: [
      {
        link:
          '/psicologos/santos/boqueirao?lat=-23.9648026&long=-46.3235849&address=Boqueir%C3%A3o-Santos-SP',
        slug_city: 'santos',
        city: 'Santos',
        slug_neighborhood: 'boqueirao',
        neighborhood: 'Boqueirão',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santos/gonzaga?lat=-23.9673822&long=-46.3328146&address=Gonzaga-Santos-SP',
        slug_city: 'santos',
        city: 'Santos',
        slug_neighborhood: 'gonzaga',
        neighborhood: 'Gonzaga',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santos/vila-belmiro?lat=-23.9510794&long=-46.3388549&address=Vila+Belmiro-Santos-SP',
        slug_city: 'santos',
        city: 'Santos',
        slug_neighborhood: 'vila-belmiro',
        neighborhood: 'Vila Belmiro',
        state: 'SP'
      }
    ],
    name: 'Santos'
  },
  {
    slug: 'goiania',
    neighborhoods: [
      {
        link:
          '/psicologos/goiania/jardim-goias?lat=-16.6938273&long=-49.2385339&address=Jardim+Goi%C3%A1s-Goi%C3%A2nia-GO',
        slug_city: 'goiania',
        city: 'Goiânia',
        slug_neighborhood: 'jardim-goias',
        neighborhood: 'Jardim Goiás',
        state: 'GO'
      },
      {
        link:
          '/psicologos/goiania/serrinha?lat=-16.7220116&long=-49.2664018&address=Serrinha-Goi%C3%A2nia-GO',
        slug_city: 'goiania',
        city: 'Goiânia',
        slug_neighborhood: 'serrinha',
        neighborhood: 'Serrinha',
        state: 'GO'
      },
      {
        link:
          '/psicologos/goiania/setor-bueno?lat=-16.7004399&long=-49.276539&address=Setor+Bueno-Goi%C3%A2nia-GO',
        slug_city: 'goiania',
        city: 'Goiânia',
        slug_neighborhood: 'setor-bueno',
        neighborhood: 'Setor Bueno',
        state: 'GO'
      },
      {
        link:
          '/psicologos/goiania/setor-marista?lat=-16.6996858&long=-49.2612782&address=Setor+Marista-Goi%C3%A2nia-GO',
        slug_city: 'goiania',
        city: 'Goiânia',
        slug_neighborhood: 'setor-marista',
        neighborhood: 'Setor Marista',
        state: 'GO'
      },
      {
        link:
          '/psicologos/goiania/setor-oeste?lat=-16.6848554&long=-49.2714702&address=Setor+Oeste-Goi%C3%A2nia-GO',
        slug_city: 'goiania',
        city: 'Goiânia',
        slug_neighborhood: 'setor-oeste',
        neighborhood: 'Setor Oeste',
        state: 'GO'
      }
    ],
    name: 'Goiânia'
  },
  {
    slug: 'maringa',
    neighborhoods: [
      {
        link:
          '/psicologos/maringa/jardim-aclimacao?lat=-23.4452572&long=-51.9176823&address=Jardim+Aclima%C3%A7%C3%A3o-Maring%C3%A1-PR',
        slug_city: 'maringa',
        city: 'Maringá',
        slug_neighborhood: 'jardim-aclimacao',
        neighborhood: 'Jardim Aclimação',
        state: 'PR'
      },
      {
        link:
          '/psicologos/maringa/zona-01?lat=-23.4194131&long=-51.9394994&address=Zona+01-Maring%C3%A1-PR',
        slug_city: 'maringa',
        city: 'Maringá',
        slug_neighborhood: 'zona-01',
        neighborhood: 'Zona 01',
        state: 'PR'
      },
      {
        link:
          '/psicologos/maringa/zona-03?lat=-23.4265804&long=-51.9249539&address=Zona+03-Maring%C3%A1-PR',
        slug_city: 'maringa',
        city: 'Maringá',
        slug_neighborhood: 'zona-03',
        neighborhood: 'Zona 03',
        state: 'PR'
      }
    ],
    name: 'Maringá'
  },
  {
    slug: 'niteroi',
    neighborhoods: [
      {
        link:
          '/psicologos/niteroi/centro?lat=-22.8909475&long=-43.1188789&address=Centro-Niter%C3%B3i-RJ',
        slug_city: 'niteroi',
        city: 'Niterói',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/niteroi/icarai?lat=-22.9043971&long=-43.1093638&address=Icara%C3%AD-Niter%C3%B3i-RJ',
        slug_city: 'niteroi',
        city: 'Niterói',
        slug_neighborhood: 'icarai',
        neighborhood: 'Icaraí',
        state: 'RJ'
      }
    ],
    name: 'Niterói'
  },
  {
    slug: 'nova-iguacu',
    neighborhoods: [
      {
        link:
          '/psicologos/nova-iguacu/centro?lat=-22.7561319&long=-43.4607419&address=Centro-Nova+Igua%C3%A7u-RJ',
        slug_city: 'nova-iguacu',
        city: 'Nova Iguaçu',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'RJ'
      }
    ],
    name: 'Nova Iguaçu'
  },
  {
    slug: 'sorocaba',
    neighborhoods: [
      {
        link:
          '/psicologos/sorocaba/centro?lat=-23.5003362&long=-47.4563943&address=Centro-Sorocaba-SP',
        slug_city: 'sorocaba',
        city: 'Sorocaba',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sorocaba/jardim-america?lat=-23.5204925&long=-47.4698804&address=Jardim+Am%C3%A9rica-Sorocaba-SP',
        slug_city: 'sorocaba',
        city: 'Sorocaba',
        slug_neighborhood: 'jardim-america',
        neighborhood: 'Jardim América',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sorocaba/jardim-vergueiro?lat=-23.505984&long=-47.460685&address=Jardim+Vergueiro-Sorocaba-SP',
        slug_city: 'sorocaba',
        city: 'Sorocaba',
        slug_neighborhood: 'jardim-vergueiro',
        neighborhood: 'Jardim Vergueiro',
        state: 'SP'
      }
    ],
    name: 'Sorocaba'
  },
  {
    slug: 'sao-caetano-do-sul',
    neighborhoods: [
      {
        link:
          '/psicologos/sao-caetano-do-sul/centro?lat=-23.612226&long=-46.5752628&address=Centro-S%C3%A3o+Caetano+do+Sul-SP',
        slug_city: 'sao-caetano-do-sul',
        city: 'São Caetano do Sul',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-caetano-do-sul/ceramica?lat=-23.626513&long=-46.5752628&address=Cer%C3%A2mica-S%C3%A3o+Caetano+do+Sul-SP',
        slug_city: 'sao-caetano-do-sul',
        city: 'São Caetano do Sul',
        slug_neighborhood: 'ceramica',
        neighborhood: 'Cerâmica',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-caetano-do-sul/maua?lat=-23.6431148&long=-46.5716484&address=Mau%C3%A1-S%C3%A3o+Caetano+do+Sul-SP',
        slug_city: 'sao-caetano-do-sul',
        city: 'São Caetano do Sul',
        slug_neighborhood: 'maua',
        neighborhood: 'Mauá',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-caetano-do-sul/osvaldo-cruz?lat=-23.6271534&long=-46.5668298&address=Osvaldo+Cruz-S%C3%A3o+Caetano+do+Sul-SP',
        slug_city: 'sao-caetano-do-sul',
        city: 'São Caetano do Sul',
        slug_neighborhood: 'osvaldo-cruz',
        neighborhood: 'Osvaldo Cruz',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-caetano-do-sul/santa-paula?lat=-23.6176297&long=-46.5668298&address=Santa+Paula-S%C3%A3o+Caetano+do+Sul-SP',
        slug_city: 'sao-caetano-do-sul',
        city: 'São Caetano do Sul',
        slug_neighborhood: 'santa-paula',
        neighborhood: 'Santa Paula',
        state: 'SP'
      }
    ],
    name: 'São Caetano do Sul'
  },
  {
    slug: 'jundiai',
    neighborhoods: [
      {
        link:
          '/psicologos/jundiai/anhangabau?lat=-23.1909011&long=-46.8999397&address=Anhangaba%C3%BA-Jundia%C3%AD-SP',
        slug_city: 'jundiai',
        city: 'Jundiaí',
        slug_neighborhood: 'anhangabau',
        neighborhood: 'Anhangabaú',
        state: 'SP'
      },
      {
        link:
          '/psicologos/jundiai/centro?lat=-23.1873363&long=-46.8880201&address=Centro-Jundia%C3%AD-SP',
        slug_city: 'jundiai',
        city: 'Jundiaí',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      }
    ],
    name: 'Jundiaí'
  },
  {
    slug: 'recife',
    neighborhoods: [
      {
        link:
          '/psicologos/recife/boa-viagem?lat=-8.1317303&long=-34.902409&address=Boa+Viagem-Recife-PE',
        slug_city: 'recife',
        city: 'Recife',
        slug_neighborhood: 'boa-viagem',
        neighborhood: 'Boa Viagem',
        state: 'PE'
      },
      {
        link:
          '/psicologos/recife/boa-vista?lat=-8.0559795&long=-34.8953473&address=Boa+Vista-Recife-PE',
        slug_city: 'recife',
        city: 'Recife',
        slug_neighborhood: 'boa-vista',
        neighborhood: 'Boa Vista',
        state: 'PE'
      },
      {
        link: '/psicologos/recife/ibura?lat=-8.1101563&long=-34.9365967&address=Ibura-Recife-PE',
        slug_city: 'recife',
        city: 'Recife',
        slug_neighborhood: 'ibura',
        neighborhood: 'Ibura',
        state: 'PE'
      },
      {
        link: '/psicologos/recife/ipsep?lat=-8.1073541&long=-34.923195&address=Ipsep-Recife-PE',
        slug_city: 'recife',
        city: 'Recife',
        slug_neighborhood: 'ipsep',
        neighborhood: 'Ipsep',
        state: 'PE'
      },
      {
        link:
          '/psicologos/recife/santo-amaro?lat=-8.0462309&long=-34.878635&address=Santo+Amaro-Recife-PE',
        slug_city: 'recife',
        city: 'Recife',
        slug_neighborhood: 'santo-amaro',
        neighborhood: 'Santo Amaro',
        state: 'PE'
      }
    ],
    name: 'Recife'
  },
  {
    slug: 'osasco',
    neighborhoods: [
      {
        link: '/psicologos/osasco/centro?lat=-23.5337655&long=-46.7792927&address=Centro-Osasco-SP',
        slug_city: 'osasco',
        city: 'Osasco',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/osasco/helena-maria?lat=-23.4974034&long=-46.7950217&address=Helena+Maria-Osasco-SP',
        slug_city: 'osasco',
        city: 'Osasco',
        slug_neighborhood: 'helena-maria',
        neighborhood: 'Helena Maria',
        state: 'SP'
      },
      {
        link:
          '/psicologos/osasco/vila-osasco?lat=-23.5409807&long=-46.782922&address=Vila+Osasco-Osasco-SP',
        slug_city: 'osasco',
        city: 'Osasco',
        slug_neighborhood: 'vila-osasco',
        neighborhood: 'Vila Osasco',
        state: 'SP'
      }
    ],
    name: 'Osasco'
  },
  {
    slug: 'ribeirao-preto',
    neighborhoods: [
      {
        link:
          '/psicologos/ribeirao-preto/centro?lat=-21.1781827&long=-47.8106327&address=Centro-Ribeir%C3%A3o+Preto-SP',
        slug_city: 'ribeirao-preto',
        city: 'Ribeirão Preto',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/ribeirao-preto/jardim-america?lat=-21.1960042&long=-47.8019945&address=Jardim+Am%C3%A9rica-Ribeir%C3%A3o+Preto-SP',
        slug_city: 'ribeirao-preto',
        city: 'Ribeirão Preto',
        slug_neighborhood: 'jardim-america',
        neighborhood: 'Jardim América',
        state: 'SP'
      },
      {
        link:
          '/psicologos/ribeirao-preto/jardim-iraja?lat=-21.2070067&long=-47.8031083&address=Jardim+Iraj%C3%A1-Ribeir%C3%A3o+Preto-SP',
        slug_city: 'ribeirao-preto',
        city: 'Ribeirão Preto',
        slug_neighborhood: 'jardim-iraja',
        neighborhood: 'Jardim Irajá',
        state: 'SP'
      },
      {
        link:
          '/psicologos/ribeirao-preto/jardim-sumare?lat=-21.1893921&long=-47.8118669&address=Jardim+Sumar%C3%A9-Ribeir%C3%A3o+Preto-SP',
        slug_city: 'ribeirao-preto',
        city: 'Ribeirão Preto',
        slug_neighborhood: 'jardim-sumare',
        neighborhood: 'Jardim Sumaré',
        state: 'SP'
      },
      {
        link:
          '/psicologos/ribeirao-preto/jardim-sao-luiz?lat=-21.1951267&long=-47.8006082&address=Jardim+S%C3%A3o+Luiz-Ribeir%C3%A3o+Preto-SP',
        slug_city: 'ribeirao-preto',
        city: 'Ribeirão Preto',
        slug_neighborhood: 'jardim-sao-luiz',
        neighborhood: 'Jardim São Luiz',
        state: 'SP'
      }
    ],
    name: 'Ribeirão Preto'
  },
  {
    slug: 'barueri',
    neighborhoods: [
      {
        link:
          '/psicologos/barueri/alphaville-comercial?lat=-23.4970209&long=-46.8513237&address=Alphaville+Comercial-Barueri-SP',
        slug_city: 'barueri',
        city: 'Barueri',
        slug_neighborhood: 'alphaville-comercial',
        neighborhood: 'Alphaville Comercial',
        state: 'SP'
      },
      {
        link:
          '/psicologos/barueri/alphaville-empresarial?lat=-23.48806&long=-46.8652576&address=Alphaville+Empresarial-Barueri-SP',
        slug_city: 'barueri',
        city: 'Barueri',
        slug_neighborhood: 'alphaville-empresarial',
        neighborhood: 'Alphaville Empresarial',
        state: 'SP'
      },
      {
        link:
          '/psicologos/barueri/jardim-sao-pedro?lat=-23.5102054&long=-46.8828318&address=Jardim+S%C3%A3o+Pedro-Barueri-SP',
        slug_city: 'barueri',
        city: 'Barueri',
        slug_neighborhood: 'jardim-sao-pedro',
        neighborhood: 'Jardim São Pedro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/barueri/nova-aldeinha?lat=-23.5134029&long=-46.855564&address=Nova+Aldeinha-Barueri-SP',
        slug_city: 'barueri',
        city: 'Barueri',
        slug_neighborhood: 'nova-aldeinha',
        neighborhood: 'Nova Aldeinha',
        state: 'SP'
      }
    ],
    name: 'Barueri'
  },
  {
    slug: 'brasilia',
    neighborhoods: [
      {
        link:
          '/psicologos/brasilia/areal-aguas-claras?lat=-15.8543584&long=-48.0350465&address=Areal+%28%C3%81guas+Claras%29-Bras%C3%ADlia-DF',
        slug_city: 'brasilia',
        city: 'Brasília',
        slug_neighborhood: 'areal-aguas-claras',
        neighborhood: 'Areal (Águas Claras)',
        state: 'DF'
      },
      {
        link:
          '/psicologos/brasilia/asa-norte?lat=-15.7662824&long=-47.8829416&address=Asa+Norte-Bras%C3%ADlia-DF',
        slug_city: 'brasilia',
        city: 'Brasília',
        slug_neighborhood: 'asa-norte',
        neighborhood: 'Asa Norte',
        state: 'DF'
      },
      {
        link:
          '/psicologos/brasilia/asa-sul?lat=-15.8202434&long=-47.9045093&address=Asa+Sul-Bras%C3%ADlia-DF',
        slug_city: 'brasilia',
        city: 'Brasília',
        slug_neighborhood: 'asa-sul',
        neighborhood: 'Asa Sul',
        state: 'DF'
      },
      {
        link:
          '/psicologos/brasilia/setor-sudoeste?lat=-15.7950174&long=-47.9267674&address=Setor+Sudoeste-Bras%C3%ADlia-DF',
        slug_city: 'brasilia',
        city: 'Brasília',
        slug_neighborhood: 'setor-sudoeste',
        neighborhood: 'Setor Sudoeste',
        state: 'DF'
      },
      {
        link:
          '/psicologos/brasilia/taguatinga-sul-taguatinga?lat=-15.8517848&long=-48.0418722&address=Taguatinga+Sul+%28Taguatinga%29-Bras%C3%ADlia-DF',
        slug_city: 'brasilia',
        city: 'Brasília',
        slug_neighborhood: 'taguatinga-sul-taguatinga',
        neighborhood: 'Taguatinga Sul (Taguatinga)',
        state: 'DF'
      }
    ],
    name: 'Brasília'
  },
  {
    slug: 'sao-jose-dos-campos',
    neighborhoods: [
      {
        link:
          '/psicologos/sao-jose-dos-campos/floradas-de-sao-jose?lat=-23.2189695&long=-45.8891165&address=Floradas+de+S%C3%A3o+Jos%C3%A9-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'floradas-de-sao-jose',
        neighborhood: 'Floradas de São José',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-jose-dos-campos/jardim-esplanada?lat=-23.1971865&long=-45.9027805&address=Jardim+Esplanada-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'jardim-esplanada',
        neighborhood: 'Jardim Esplanada',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-jose-dos-campos/jardim-ismenia?lat=-23.1764808&long=-45.850522&address=Jardim+Ism%C3%AAnia-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'jardim-ismenia',
        neighborhood: 'Jardim Ismênia',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-jose-dos-campos/jardim-pararangaba?lat=-23.1741063&long=-45.8101794&address=Jardim+Pararangaba-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'jardim-pararangaba',
        neighborhood: 'Jardim Pararangaba',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-jose-dos-campos/vila-adyana?lat=-23.1965961&long=-45.8926807&address=Vila+Adyana-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'vila-adyana',
        neighborhood: 'Vila Adyana',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-jose-dos-campos/vila-ema?lat=-23.2058037&long=-45.9004039&address=Vila+Ema-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'vila-ema',
        neighborhood: 'Vila Ema',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-jose-dos-campos/vila-jaci?lat=-23.1993756&long=-45.8938688&address=Vila+Jaci-S%C3%A3o+Jos%C3%A9+dos+Campos-SP',
        slug_city: 'sao-jose-dos-campos',
        city: 'São José dos Campos',
        slug_neighborhood: 'vila-jaci',
        neighborhood: 'Vila Jaci',
        state: 'SP'
      }
    ],
    name: 'São José dos Campos'
  },
  {
    slug: 'belo-horizonte',
    neighborhoods: [
      {
        link:
          '/psicologos/belo-horizonte/barro-preto?lat=-19.9233091&long=-43.9536082&address=Barro+Preto-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'barro-preto',
        neighborhood: 'Barro Preto',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/centro?lat=-19.9192706&long=-43.9386602&address=Centro-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/funcionarios?lat=-19.9308246&long=-43.9280307&address=Funcion%C3%A1rios-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'funcionarios',
        neighborhood: 'Funcionários',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/lourdes?lat=-19.931038&long=-43.943329&address=Lourdes-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'lourdes',
        neighborhood: 'Lourdes',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/planalto?lat=-19.8363874&long=-43.9472672&address=Planalto-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'planalto',
        neighborhood: 'Planalto',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/santo-agostinho?lat=-19.9294985&long=-43.9496391&address=Santo+Agostinho-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'santo-agostinho',
        neighborhood: 'Santo Agostinho',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/serra?lat=-19.9390445&long=-43.9199276&address=Serra-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'serra',
        neighborhood: 'Serra',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/sao-pedro?lat=-19.9446975&long=-43.9366932&address=S%C3%A3o+Pedro-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'sao-pedro',
        neighborhood: 'São Pedro',
        state: 'MG'
      },
      {
        link:
          '/psicologos/belo-horizonte/venda-nova?lat=-19.817231&long=-43.9519188&address=Venda+Nova-Belo+Horizonte-MG',
        slug_city: 'belo-horizonte',
        city: 'Belo Horizonte',
        slug_neighborhood: 'venda-nova',
        neighborhood: 'Venda Nova',
        state: 'MG'
      }
    ],
    name: 'Belo Horizonte'
  },
  {
    slug: 'guarulhos',
    neighborhoods: [
      {
        link:
          '/psicologos/guarulhos/centro?lat=-23.4678664&long=-46.5331122&address=Centro-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/guarulhos/gopouva?lat=-23.4664404&long=-46.5427434&address=Gopo%C3%BAva-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'gopouva',
        neighborhood: 'Gopoúva',
        state: 'SP'
      },
      {
        link:
          '/psicologos/guarulhos/jardim-tranquilidade?lat=-23.4666201&long=-46.5535808&address=Jardim+Tranq%C3%BCilidade-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'jardim-tranquilidade',
        neighborhood: 'Jardim Tranqüilidade',
        state: 'SP'
      },
      {
        link:
          '/psicologos/guarulhos/vila-aprazivel?lat=-23.4663536&long=-46.5465023&address=Vila+Apraz%C3%ADvel-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'vila-aprazivel',
        neighborhood: 'Vila Aprazível',
        state: 'SP'
      },
      {
        link:
          '/psicologos/guarulhos/vila-galvao?lat=-23.4540618&long=-46.5620116&address=Vila+Galv%C3%A3o-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'vila-galvao',
        neighborhood: 'Vila Galvão',
        state: 'SP'
      },
      {
        link:
          '/psicologos/guarulhos/vila-progresso?lat=-23.4622008&long=-46.5385866&address=Vila+Progresso-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'vila-progresso',
        neighborhood: 'Vila Progresso',
        state: 'SP'
      },
      {
        link:
          '/psicologos/guarulhos/vila-rachid?lat=-23.471541&long=-46.5258117&address=Vila+Rachid-Guarulhos-SP',
        slug_city: 'guarulhos',
        city: 'Guarulhos',
        slug_neighborhood: 'vila-rachid',
        neighborhood: 'Vila Rachid',
        state: 'SP'
      }
    ],
    name: 'Guarulhos'
  },
  {
    slug: 'sao-bernardo-do-campo',
    neighborhoods: [
      {
        link:
          '/psicologos/sao-bernardo-do-campo/assuncao?lat=-23.7244028&long=-46.5744781&address=Assun%C3%A7%C3%A3o-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'assuncao',
        neighborhood: 'Assunção',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/centro?lat=-23.7015514&long=-46.5529056&address=Centro-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/jardim-do-mar?lat=-23.6896915&long=-46.5583982&address=Jardim+do+Mar-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'jardim-do-mar',
        neighborhood: 'Jardim do Mar',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/pauliceia?lat=-23.6671936&long=-46.5861072&address=Paulic%C3%A9ia-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'pauliceia',
        neighborhood: 'Paulicéia',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/rudge-ramos?lat=-23.6577258&long=-46.5683959&address=Rudge+Ramos-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'rudge-ramos',
        neighborhood: 'Rudge Ramos',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/vila-mariza?lat=-23.6622553&long=-46.5659263&address=Vila+Mariza-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'vila-mariza',
        neighborhood: 'Vila Mariza',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/vila-marlene?lat=-23.6858034&long=-46.5626138&address=Vila+Marlene-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'vila-marlene',
        neighborhood: 'Vila Marlene',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-bernardo-do-campo/vila-mussolini?lat=-23.6676966&long=-46.5704437&address=Vila+Mussolini-S%C3%A3o+Bernardo+do+Campo-SP',
        slug_city: 'sao-bernardo-do-campo',
        city: 'São Bernardo do Campo',
        slug_neighborhood: 'vila-mussolini',
        neighborhood: 'Vila Mussolini',
        state: 'SP'
      }
    ],
    name: 'São Bernardo do Campo'
  },
  {
    slug: 'porto-alegre',
    neighborhoods: [
      {
        link:
          '/psicologos/porto-alegre/auxiliadora?lat=-30.0211612&long=-51.1925297&address=Auxiliadora-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'auxiliadora',
        neighborhood: 'Auxiliadora',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/bom-fim?lat=-30.031663&long=-51.2121474&address=Bom+Fim-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'bom-fim',
        neighborhood: 'Bom Fim',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/coronel-aparicio-borges?lat=-30.0726586&long=-51.1961488&address=Coronel+Apar%C3%ADcio+Borges-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'coronel-aparicio-borges',
        neighborhood: 'Coronel Aparício Borges',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/floresta?lat=-30.0187946&long=-51.2095987&address=Floresta-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'floresta',
        neighborhood: 'Floresta',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/jardim-botanico?lat=-30.051034&long=-51.179255&address=Jardim+Bot%C3%A2nico-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'jardim-botanico',
        neighborhood: 'Jardim Botânico',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/moinhos-de-vento?lat=-30.0248056&long=-51.2029918&address=Moinhos+de+Vento-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'moinhos-de-vento',
        neighborhood: 'Moinhos de Vento',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/petropolis?lat=-30.0443102&long=-51.1863775&address=Petr%C3%B3polis-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'petropolis',
        neighborhood: 'Petrópolis',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/rio-branco?lat=-30.0334155&long=-51.2041059&address=Rio+Branco-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'rio-branco',
        neighborhood: 'Rio Branco',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/santana?lat=-30.0458948&long=-51.2039935&address=Santana-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'santana',
        neighborhood: 'Santana',
        state: 'RS'
      },
      {
        link:
          '/psicologos/porto-alegre/tres-figueiras?lat=-30.0346256&long=-51.170145&address=Tr%C3%AAs+Figueiras-Porto+Alegre-RS',
        slug_city: 'porto-alegre',
        city: 'Porto Alegre',
        slug_neighborhood: 'tres-figueiras',
        neighborhood: 'Três Figueiras',
        state: 'RS'
      }
    ],
    name: 'Porto Alegre'
  },
  {
    slug: 'curitiba',
    neighborhoods: [
      {
        link:
          '/psicologos/curitiba/ahu?lat=-25.4039901&long=-49.2601858&address=Ah%C3%BA-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'ahu',
        neighborhood: 'Ahú',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/bacacheri?lat=-25.3971998&long=-49.2367179&address=Bacacheri-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'bacacheri',
        neighborhood: 'Bacacheri',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/bigorrilho?lat=-25.4332606&long=-49.2971565&address=Bigorrilho-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'bigorrilho',
        neighborhood: 'Bigorrilho',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/centro?lat=-25.4342388&long=-49.2713753&address=Centro-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/centro-civico?lat=-25.4174893&long=-49.2687071&address=Centro+C%C3%ADvico-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'centro-civico',
        neighborhood: 'Centro Cívico',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/hugo-lange?lat=-25.4175884&long=-49.2454469&address=Hugo+Lange-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'hugo-lange',
        neighborhood: 'Hugo Lange',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/merces?lat=-25.4220894&long=-49.2918813&address=Merc%C3%AAs-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'merces',
        neighborhood: 'Mercês',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/portao?lat=-25.4749219&long=-49.3007845&address=Port%C3%A3o-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'portao',
        neighborhood: 'Portão',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/santa-candida?lat=-25.370598&long=-49.2342979&address=Santa+C%C3%A2ndida-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'santa-candida',
        neighborhood: 'Santa Cândida',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/sao-francisco?lat=-25.4234212&long=-49.2757774&address=S%C3%A3o+Francisco-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'sao-francisco',
        neighborhood: 'São Francisco',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/uberaba?lat=-25.4858632&long=-49.2193668&address=Uberaba-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'uberaba',
        neighborhood: 'Uberaba',
        state: 'PR'
      },
      {
        link:
          '/psicologos/curitiba/agua-verde?lat=-25.4530706&long=-49.2779229&address=%C3%81gua+Verde-Curitiba-PR',
        slug_city: 'curitiba',
        city: 'Curitiba',
        slug_neighborhood: 'agua-verde',
        neighborhood: 'Água Verde',
        state: 'PR'
      }
    ],
    name: 'Curitiba'
  },
  {
    slug: 'santo-andre',
    neighborhoods: [
      {
        link:
          '/psicologos/santo-andre/casa-branca?lat=-23.6609707&long=-46.5202726&address=Casa+Branca-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'casa-branca',
        neighborhood: 'Casa Branca',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santo-andre/centro?lat=-23.6613394&long=-46.5289624&address=Centro-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santo-andre/jardim?lat=-23.6316071&long=-46.442906&address=Jardim-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'jardim',
        neighborhood: 'Jardim',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santo-andre/jardim-bela-vista?lat=-23.666936&long=-46.5403677&address=Jardim+Bela+Vista-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'jardim-bela-vista',
        neighborhood: 'Jardim Bela Vista',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santo-andre/parque-das-nacoes?lat=-23.6383643&long=-46.5187561&address=Parque+das+Na%C3%A7%C3%B5es-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'parque-das-nacoes',
        neighborhood: 'Parque das Nações',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santo-andre/vila-assuncao?lat=-23.6720608&long=-46.5268749&address=Vila+Assun%C3%A7%C3%A3o-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'vila-assuncao',
        neighborhood: 'Vila Assunção',
        state: 'SP'
      },
      {
        link:
          '/psicologos/santo-andre/vila-humaita?lat=-23.6739456&long=-46.5034129&address=Vila+Humait%C3%A1-Santo+Andr%C3%A9-SP',
        slug_city: 'santo-andre',
        city: 'Santo André',
        slug_neighborhood: 'vila-humaita',
        neighborhood: 'Vila Humaitá',
        state: 'SP'
      }
    ],
    name: 'Santo André'
  },
  {
    slug: 'salvador',
    neighborhoods: [
      {
        link:
          '/psicologos/salvador/brotas?lat=-12.9857613&long=-38.4998158&address=Brotas-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'brotas',
        neighborhood: 'Brotas',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/cabula?lat=-12.9580582&long=-38.4698385&address=Cabula-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'cabula',
        neighborhood: 'Cabula',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/caminho-das-arvores?lat=-12.9778544&long=-38.4605517&address=Caminho+das+%C3%81rvores-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'caminho-das-arvores',
        neighborhood: 'Caminho das Árvores',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/candeal?lat=-12.9921219&long=-38.4825103&address=Candeal-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'candeal',
        neighborhood: 'Candeal',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/canela?lat=-12.9920827&long=-38.5224338&address=Canela-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'canela',
        neighborhood: 'Canela',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/graca?lat=-12.9985064&long=-38.5230905&address=Gra%C3%A7a-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'graca',
        neighborhood: 'Graça',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/itaigara?lat=-12.9909398&long=-38.4727353&address=Itaigara-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'itaigara',
        neighborhood: 'Itaigara',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/parque-bela-vista?lat=-12.9858902&long=-38.4710595&address=Parque+Bela+Vista-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'parque-bela-vista',
        neighborhood: 'Parque Bela Vista',
        state: 'BA'
      },
      {
        link:
          '/psicologos/salvador/pituba?lat=-13.0025439&long=-38.4566915&address=Pituba-Salvador-BA',
        slug_city: 'salvador',
        city: 'Salvador',
        slug_neighborhood: 'pituba',
        neighborhood: 'Pituba',
        state: 'BA'
      }
    ],
    name: 'Salvador'
  },
  {
    slug: 'campinas',
    neighborhoods: [
      {
        link:
          '/psicologos/campinas/barao-geraldo?lat=-22.8226801&long=-47.0850446&address=Bar%C3%A3o+Geraldo-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'barao-geraldo',
        neighborhood: 'Barão Geraldo',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/bosque?lat=-22.909649&long=-47.0497877&address=Bosque-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'bosque',
        neighborhood: 'Bosque',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/cambui?lat=-22.8921264&long=-47.0546529&address=Cambu%C3%AD-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'cambui',
        neighborhood: 'Cambuí',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/centro?lat=-22.9101744&long=-47.0593274&address=Centro-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/chacara-da-barra?lat=-22.8880278&long=-47.0351952&address=Ch%C3%A1cara+da+Barra-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'chacara-da-barra',
        neighborhood: 'Chácara da Barra',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/jardim-chapadao?lat=-22.8921646&long=-47.0815618&address=Jardim+Chapad%C3%A3o-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'jardim-chapadao',
        neighborhood: 'Jardim Chapadão',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/jardim-guanabara?lat=-22.8922073&long=-47.069251&address=Jardim+Guanabara-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'jardim-guanabara',
        neighborhood: 'Jardim Guanabara',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/jardim-guarani?lat=-22.9118622&long=-47.0359622&address=Jardim+Guarani-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'jardim-guarani',
        neighborhood: 'Jardim Guarani',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/jardim-proenca?lat=-22.9155777&long=-47.0406669&address=Jardim+Proen%C3%A7a-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'jardim-proenca',
        neighborhood: 'Jardim Proença',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/jardim-santa-genebra-ii-barao-geraldo?lat=-22.8333161&long=-47.0812919&address=Jardim+Santa+Genebra+II+%28Bar%C3%A3o+Geraldo%29-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'jardim-santa-genebra-ii-barao-geraldo',
        neighborhood: 'Jardim Santa Genebra II (Barão Geraldo)',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/jardim-do-lago?lat=-22.9323504&long=-47.0839036&address=Jardim+do+Lago-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'jardim-do-lago',
        neighborhood: 'Jardim do Lago',
        state: 'SP'
      },
      {
        link:
          '/psicologos/campinas/taquaral?lat=-22.882513&long=-47.0473554&address=Taquaral-Campinas-SP',
        slug_city: 'campinas',
        city: 'Campinas',
        slug_neighborhood: 'taquaral',
        neighborhood: 'Taquaral',
        state: 'SP'
      }
    ],
    name: 'Campinas'
  },
  {
    slug: 'rio-de-janeiro',
    neighborhoods: [
      {
        link:
          '/psicologos/rio-de-janeiro/barra-da-tijuca?lat=-23.0003709&long=-43.365895&address=Barra+da+Tijuca-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'barra-da-tijuca',
        neighborhood: 'Barra da Tijuca',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/botafogo?lat=-22.9511149&long=-43.1809269&address=Botafogo-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'botafogo',
        neighborhood: 'Botafogo',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/campo-grande?lat=-22.9035842&long=-43.5662438&address=Campo+Grande-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'campo-grande',
        neighborhood: 'Campo Grande',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/catete?lat=-22.9279602&long=-43.1807701&address=Catete-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'catete',
        neighborhood: 'Catete',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/centro?lat=-22.9070828&long=-43.1819148&address=Centro-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'centro',
        neighborhood: 'Centro',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/copacabana?lat=-22.9697777&long=-43.1868592&address=Copacabana-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'copacabana',
        neighborhood: 'Copacabana',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/flamengo?lat=-22.9331294&long=-43.1774267&address=Flamengo-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'flamengo',
        neighborhood: 'Flamengo',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/freguesia-jacarepagua?lat=-22.9348995&long=-43.3360471&address=Freguesia+%28Jacarepagu%C3%A1%29-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'freguesia-jacarepagua',
        neighborhood: 'Freguesia (Jacarepaguá)',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/gavea?lat=-22.9760044&long=-43.2285147&address=G%C3%A1vea-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'gavea',
        neighborhood: 'Gávea',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/ipanema?lat=-22.984667&long=-43.1985932&address=Ipanema-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'ipanema',
        neighborhood: 'Ipanema',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/jardim-botanico?lat=-22.9641719&long=-43.2227728&address=Jardim+Bot%C3%A2nico-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'jardim-botanico',
        neighborhood: 'Jardim Botânico',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/leblon?lat=-22.9843373&long=-43.2231424&address=Leblon-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'leblon',
        neighborhood: 'Leblon',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/madureira?lat=-22.8725334&long=-43.3363639&address=Madureira-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'madureira',
        neighborhood: 'Madureira',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/maracana?lat=-22.9127036&long=-43.2261662&address=Maracan%C3%A3-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'maracana',
        neighborhood: 'Maracanã',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/meier?lat=-22.9021344&long=-43.2803832&address=M%C3%A9ier-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'meier',
        neighborhood: 'Méier',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/oswaldo-cruz?lat=-22.8749746&long=-43.351177&address=Oswaldo+Cruz-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'oswaldo-cruz',
        neighborhood: 'Oswaldo Cruz',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/recreio-dos-bandeirantes?lat=-23.0136663&long=-43.4634068&address=Recreio+dos+Bandeirantes-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'recreio-dos-bandeirantes',
        neighborhood: 'Recreio dos Bandeirantes',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/taquara?lat=-22.9230494&long=-43.3739788&address=Taquara-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'taquara',
        neighborhood: 'Taquara',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/tijuca?lat=-22.9325728&long=-43.2410248&address=Tijuca-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'tijuca',
        neighborhood: 'Tijuca',
        state: 'RJ'
      },
      {
        link:
          '/psicologos/rio-de-janeiro/vila-da-penha?lat=-22.8440802&long=-43.3094623&address=Vila+da+Penha-Rio+de+Janeiro-RJ',
        slug_city: 'rio-de-janeiro',
        city: 'Rio de Janeiro',
        slug_neighborhood: 'vila-da-penha',
        neighborhood: 'Vila da Penha',
        state: 'RJ'
      }
    ],
    name: 'Rio de Janeiro'
  },
  {
    slug: 'sao-paulo',
    neighborhoods: [
      {
        link:
          '/psicologos/sao-paulo/aclimacao?lat=-23.571487&long=-46.6309716&address=Aclima%C3%A7%C3%A3o-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'aclimacao',
        neighborhood: 'Aclimação',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/bela-vista?lat=-23.5628311&long=-46.6462595&address=Bela+Vista-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'bela-vista',
        neighborhood: 'Bela Vista',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/cerqueira-cesar?lat=-23.5592144&long=-46.6677317&address=Cerqueira+C%C3%A9sar-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'cerqueira-cesar',
        neighborhood: 'Cerqueira César',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/chacara-santo-antonio-zona-sul?lat=-23.630928&long=-46.7041338&address=Ch%C3%A1cara+Santo+Ant%C3%B4nio+%28Zona+Sul%29-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'chacara-santo-antonio-zona-sul',
        neighborhood: 'Chácara Santo Antônio (Zona Sul)',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/consolacao?lat=-23.5527045&long=-46.6588642&address=Consola%C3%A7%C3%A3o-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'consolacao',
        neighborhood: 'Consolação',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/higienopolis?lat=-23.5457512&long=-46.6599426&address=Higien%C3%B3polis-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'higienopolis',
        neighborhood: 'Higienópolis',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/indianopolis?lat=-23.6124759&long=-46.661547&address=Indian%C3%B3polis-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'indianopolis',
        neighborhood: 'Indianópolis',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/itaim-bibi?lat=-23.5858164&long=-46.6826257&address=Itaim+Bibi-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'itaim-bibi',
        neighborhood: 'Itaim Bibi',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/jardim-paulista?lat=-23.5693243&long=-46.6565455&address=Jardim+Paulista-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'jardim-paulista',
        neighborhood: 'Jardim Paulista',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/paraiso?lat=-23.5747597&long=-46.6487863&address=Para%C3%ADso-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'paraiso',
        neighborhood: 'Paraíso',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/perdizes?lat=-23.5369017&long=-46.6743166&address=Perdizes-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'perdizes',
        neighborhood: 'Perdizes',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/pinheiros?lat=-23.5630037&long=-46.6864347&address=Pinheiros-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'pinheiros',
        neighborhood: 'Pinheiros',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/santana?lat=-23.498181&long=-46.6263306&address=Santana-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'santana',
        neighborhood: 'Santana',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/sumare?lat=-23.5445118&long=-46.6847155&address=Sumar%C3%A9-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'sumare',
        neighborhood: 'Sumaré',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/tatuape?lat=-23.5352452&long=-46.575463&address=Tatuap%C3%A9-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'tatuape',
        neighborhood: 'Tatuapé',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/vila-clementino?lat=-23.5983156&long=-46.6439626&address=Vila+Clementino-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'vila-clementino',
        neighborhood: 'Vila Clementino',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/vila-gomes-cardim?lat=-23.5450184&long=-46.5637407&address=Vila+Gomes+Cardim-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'vila-gomes-cardim',
        neighborhood: 'Vila Gomes Cardim',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/vila-mariana?lat=-23.5870563&long=-46.6357437&address=Vila+Mariana-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'vila-mariana',
        neighborhood: 'Vila Mariana',
        state: 'SP'
      },
      {
        link:
          '/psicologos/sao-paulo/vila-nova-conceicao?lat=-23.5917828&long=-46.6727333&address=Vila+Nova+Concei%C3%A7%C3%A3o-S%C3%A3o+Paulo-SP',
        slug_city: 'sao-paulo',
        city: 'São Paulo',
        slug_neighborhood: 'vila-nova-conceicao',
        neighborhood: 'Vila Nova Conceição',
        state: 'SP'
      }
    ],
    name: 'São Paulo'
  }
]

export const listOfHoursToPsychologistSchedule = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30'
]

export const genderList = [
  {
    label: 'Masculino',
    value: 'M'
  },
  {
    label: 'Feminino',
    value: 'F'
  },
  {
    label: 'Não-binário',
    value: 'NB'
  },
  {
    label: 'Prefiro não responder',
    value: 'NI'
  }
]

export const accountTypeList = [
  { label: 'Corrente', value: '0' },
  { label: 'Poupança', value: '1' }
]

export const races = [
  { label: 'Branca', value: 0 },
  { label: 'Preta', value: 1 },
  { label: 'Parda', value: 2 },
  { label: 'Amarela', value: 3 },
  { label: 'Indígena', value: 4 }
]

export const idsOfProfsAllowedForBetaTestOfAnnualPaymentStatementTab = [
  '3a7777c9-4521-41e0-8186-81301477c390', // Psico de testes
  '8c62bc4c-6392-4db2-b9f2-9f995d0578b1', // Marinuta de Almeida Cattoni
  'e6e5a7cd-f370-4821-a3cd-5b1f7dc1e3c2', // Marina Guardiola Deuner Mello
  'f7af2cbc-13c0-47af-9deb-57911c1b6077', // Elisheva Patricia Cesco Martinelli
  'a94c480d-a0ed-4f8f-a68d-78aa13ebc22f', // Rosana Aparecida da Paixao
  'd3a238c4-0e75-4935-8d6d-923a85f06496', // Aline Fulconi
  'b7ae5271-50da-43a6-a669-a0786603a4d9', // Luis Eduardo Cury de Moura
  '9b4f6394-015e-4f21-a9e9-2523b19b2cd1', // Barbara Vieira dos Santos
  '47987f4e-1944-4e62-9ffc-765850a2e589', // Camila Yume Bertoluzzi
  'bc9b2d72-1ee1-42af-8c06-817854c1c422', // Egon Juliano de Almeida
  '5b363817-345b-4d8d-9979-00edaff221d9', // Artur Fernandes
  '3b05c0d6-1e4c-4356-91be-a3941daf1a2f', // Marilene Philot Fernandes
  'e6e5a7cd-f370-4821-a3cd-5b1f7dc1e3c2' // Marina Guardiola Deuner Mello
]

export const listOfHoursToPsychologistScheduleOnMorning = [
  { value: '06:30', label: '06:30' },
  { value: '07:00', label: '07:00' },
  { value: '07:30', label: '07:30' },
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' }
]
export const listOfHoursToPsychologistScheduleOnAfternoon = [
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' }
]
export const listOfHoursToPsychologistScheduleOnNight = [
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' }
]
export const listOfHoursToPsychologistScheduleOnDawn = [
  { value: '00:00', label: '00:00' },
  { value: '00:30', label: '00:30' },
  { value: '1:00', label: '1:00' },
  { value: '1:30', label: '1:30' },
  { value: '2:00', label: '2:00' },
  { value: '2:30', label: '2:30' },
  { value: '3:00', label: '3:00' },
  { value: '3:30', label: '3:30' },
  { value: '4:00', label: '4:00' },
  { value: '4:30', label: '4:30' },
  { value: '5:00', label: '5:00' },
  { value: '5:30', label: '5:30' },
  { value: '6:00', label: '6:00' }
]

export const orderListFilterFinancial = [
  {
    title: 'Data da consulta',
    options: [
      {
        label: 'Mais recente para mais antiga',
        value: [
          {
            order_by: 'date'
          },
          {
            order: 'desc'
          }
        ]
      },
      {
        label: 'Mais antiga para mais recente',
        value: [
          {
            order_by: 'date'
          },
          {
            order: 'asc'
          }
        ]
      }
    ]
  },
  {
    title: 'Paciente',
    options: [
      {
        label: 'Ordem alfabética',
        value: [
          {
            order_by: 'patient_name'
          },
          {
            order: null
          }
        ]
      }
    ]
  }
]

export const STATES_WITH_ACRONYMS = [
  {
    nome: 'Acre',
    sigla: 'AC'
  },
  {
    nome: 'Alagoas',
    sigla: 'AL'
  },
  {
    nome: 'Amazonas',
    sigla: 'AM'
  },
  {
    nome: 'Amapá',
    sigla: 'AP'
  },
  {
    nome: 'Bahia',
    sigla: 'BA'
  },
  {
    nome: 'Ceará',
    sigla: 'CE'
  },
  {
    nome: 'Distrito Federal',
    sigla: 'DF'
  },
  {
    nome: 'Espírito Santo',
    sigla: 'ES'
  },
  {
    nome: 'Goiás',
    sigla: 'GO'
  },
  {
    nome: 'Maranhão',
    sigla: 'MA'
  },
  {
    nome: 'Minas Gerais',
    sigla: 'MG'
  },
  {
    nome: 'Mato Grosso do Sul',
    sigla: 'MS'
  },
  {
    nome: 'Mato Grosso',
    sigla: 'MT'
  },
  {
    nome: 'Pará',
    sigla: 'PA'
  },
  {
    nome: 'Paraíba',
    sigla: 'PB'
  },
  {
    nome: 'Pernambuco',
    sigla: 'PE'
  },
  {
    nome: 'Piauí',
    sigla: 'PI'
  },
  {
    nome: 'Paraná',
    sigla: 'PR'
  },
  {
    nome: 'Rio de Janeiro',
    sigla: 'RJ'
  },
  {
    nome: 'Rio Grande do Norte',
    sigla: 'RN'
  },
  {
    nome: 'Rondônia',
    sigla: 'RO'
  },
  {
    nome: 'Roraima',
    sigla: 'RR'
  },
  {
    nome: 'Rio Grande do Sul',
    sigla: 'RS'
  },
  {
    nome: 'Santa Catarina',
    sigla: 'SC'
  },
  {
    nome: 'São Paulo',
    sigla: 'SP'
  },
  {
    nome: 'Sergipe',
    sigla: 'SE'
  },
  {
    nome: 'Tocantins',
    sigla: 'TO'
  }
]

export const testimonialsEnterprisePost = [
  {
    text:
      'Muita empresa está contratando o serviço para ajudar os colaboradores em home office. Fazer terapia online não é mais um tabu. Em tempos de isolamento social é necessidade.',
    href:
      'https://revistapegn.globo.com/Mulheres-empreendedoras/noticia/2019/05/cartier-premia-empreendedoras-de-impacto-de-todo-o-mundo.html',
    image: '/static/images/logos-midia/g1.png',
    image_webp: '/static/images/logos-midia/g1.webp'
  },
  {
    text:
      'O isolamento social impulsionou os serviços da Vittude, startup dona de uma espécie de “Uber de psicólogos”, onde é possível fazer terapia à distância.',
    href:
      'https://exame.com/negocios/5-negocios-que-conquistaram-um-novo-tipo-de-cliente-em-meio-a-pandemia/',
    image: '/static/images/logos-midia/exame.png',
    image_webp: '/static/images/logos-midia/exame.webp'
  },
  {
    text:
      'Tatiana Pimenta largou sua carreira de executiva como engenheira e criou em 2016 a Vittude, uma plataforma digital que conecta psicólogos e pacientes.',
    href:
      'https://epocanegocios.globo.com/Empreendedorismo/noticia/2019/05/ela-usou-um-problema-como-inspiracao-para-criar-uma-startup-que-conecta-psicologos-e-pacientes.html',
    image: '/static/images/logos-midia/epoca.png',
    image_webp: '/static/images/logos-midia/epoca.webp'
  },
  {
    text:
      'Em março, a Vittude, site que promove sessões on-line com psicólogos, ganhou seis novos clientes corporativos - que se somaram à base de 20 já existente.',
    href:
      'https://valor.globo.com/carreira/noticia/2020/04/06/terapia-on-line-cresce-nas-corporacoes.ghtml',
    image: '/static/images/logos-midia/valor.png',
    image_webp: '/static/images/logos-midia/valor.webp'
  },
  {
    text:
      'Desde março, no início das regras de quarentena, até o final de junho, a Vittude tem observado um crescimento expressivo no número de pacientes cadastrados na plataforma de atendimento online.',
    href:
      'https://veja.abril.com.br/blog/radar/busca-por-saude-mental-tem-crescimento-expressivo-na-pandemia/',
    image: '/static/images/logos-midia/veja.png',
    image_webp: '/static/images/logos-midia/veja.webp'
  },
  {
    text:
      'Beyond reducing costs related to mental health problems, companies are also starting to treat the issue more strategically, according to Vittude’s cofounder Tatiana Pimenta.',
    href:
      'https://www.forbes.com/sites/angelicamarideoliveira/2019/11/13/brazils-vittude-targets-corporates-to-democratize-therapy-access/#74862e897a3d',
    image: '/static/images/logos-midia/forbes.png',
    image_webp: '/static/images/logos-midia/forbes.webp'
  }
]

export const VITTUDE_APP_STORE_LINK = {
  Android: 'https://play.google.com/store/apps/details?id=com.vittudeapp',
  iOS: 'https://apps.apple.com/br/app/vittude/id1482314196'
}

export const SANEPAR_CONTRACT_ID = 'fc237576-870e-4c6c-94a2-4940ad2b24a1'
