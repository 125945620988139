import { useMutation } from '@tanstack/react-query'

import api, { __API__ } from './api'
import { getToken } from './auth'
import axios from 'axios'
import moment from 'moment'
import { dataURLToBlob, getCookie } from '../utils/formatters'
import { getTypes } from './type'

export const createPatient = user => {
  const newUser = { ...user }
  newUser.backend = 'nosocial'
  newUser.role = 'patient'
  if (getCookie('rdtrk')) {
    newUser.client_id = JSON.parse(decodeURIComponent(getCookie('rdtrk'))).id
  }
  newUser.birthday = moment(newUser.birthday, 'DD/MM/YYYY').format('YYYYMMDD')

  return new Promise((resolve, reject) => {
    api
      .post('/create/', newUser)
      .then(res => {
        resolve(res.data)
      })
      .catch(error => reject(error))
  })
}

export const addPartnership = async (partnershipId, userId) => {
  await api.post(`/patient/${userId}/set_allowed_user/`, {
    partnership: partnershipId
  })
}

export const createPsychologist = user => {
  const newUser = { ...user }
  newUser.birthday = moment(newUser.birthday, 'DD/MM/YYYY').format('YYYYMMDD')
  if (!user.address && !user.address.latitude) {
    newUser.address.latitude = 0.0
  }
  if (!user.address && !user.address.longitude) {
    newUser.address.longitude = 0.0
  }
  if (!user.newsletter) {
    newUser.newsletter = false
  }
  delete newUser.degree_file_input
  delete newUser.crp_file_input
  return new Promise((resolve, reject) => {
    getTypes()
      .then(res => {
        newUser.professional_type = res.data.filter(function(e, i) {
          if (e.title.toLowerCase() == 'psicólogo') {
            return e
          }
        })[0].id
        api
          .post('/prof_create/', newUser)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
      .catch(error => reject(error))
  })
}

export const updatePatient = (patient, patient_id) => {
  const newUser = { ...patient }
  newUser.backend = 'nosocial'
  newUser.role = 'patient'
  delete newUser.token
  if (getCookie('rdtrk')) {
    newUser.client_id = JSON.parse(decodeURIComponent(getCookie('rdtrk'))).id
  }
  newUser.birthday = moment(newUser.birthday, 'DD/MM/YYYY').format('YYYYMMDD')
  return new Promise((resolve, reject) => {
    api
      .post(`/patient/${patient_id}/update_profile/`, newUser)
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        if (error.response) {
          reject(new Error(error.response.data.log_content.error_code))
        }
      })
  })
}

export const getWhatsUrl = (patient_id, psico_id) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/patient/${patient_id}/get_whats_url/`, { psico_id: psico_id })
      .then(res => {
        dataLayer.push({
          event: 'Whats'
        })
        resolve(res.data)
      })
      .catch(error => {
        reject(error.response.data.log_content.error_code)
      })
  })
}

export const updatePsycho = (psycho, psycho_id) => {
  const newUser = { ...psycho }
  delete newUser.token
  newUser.birthday = moment(newUser.birthday, 'DD/MM/YYYY').format('YYYYMMDD')
  return new Promise((resolve, reject) => {
    api
      .post(`/professional/${psycho_id}/update_profile/`, newUser)
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        reject(error.response.data.log_content.error_code)
      })
  })
}

export const updatePassword = passwords => {
  return new Promise((resolve, reject) => {
    api
      .post('/update_password/', passwords)
      .then(res => resolve(res.data))
      .catch(error => reject(error))
  })
}

export const updatePhoto = async (dataUrl, name) => {
  let data = new FormData()
  const photo = dataURLToBlob(dataUrl)
  data.append('profile_photo', photo, name)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: getToken()
    }
  }
  return axios.post(`${__API__}/update_photo/`, data, config)
}

export const cancelAccount = async (user_id, cancel_motivation) =>
  api.post(`/professional/${user_id}/cancel_account/`, {
    cancel_motivation
  })

export const patientResetPassword = async email => {
  return api.post('/password/reset/', { email })
}

export const psychoResetPassword = async documentData => {
  return api.post('/password/reset/', documentData)
}

export const addDependent = (patientId, data) =>
  api.post(`/patient/${patientId}/set_dependent/`, data)

export const passwordReset = async (uid, token, data) =>
  api.post(`/password/reset/confirm/${uid}/${token}/`, data)

export const sendTracking = async data => {
  await api.post('/tracking/user/', data)
  localStorage.removeItem('utm-tracking')
}

export const useInsertDependent = (patientId, config = {}) =>
  useMutation(
    async data => {
      const response = await addDependent(patientId, data)
      return response?.data?.content
    },
    { ...config }
  )
