import api from './api'

export const createMatchMaker = data => api.post('/start_quiz_matchmaking/', data)

export const fetchQuestions = id_session => api.post('/get_questions_matchmaking/', { id_session })

export const saveMatchMakerIds = ({ session_ids, id }) =>
  api.post(`/set_sessions_matchmaking/${id}/`, { session_ids })

export const setMatchMakerQuestion = questionInfo =>
  api.post('/set_answer_matchmaking/', questionInfo)

export const fetchMatches = ({ id_session, page, contract_id, timezone }) =>
  api.post('get_prof_matchmaking/', { id_session, page, contract_id, timezone })
