import React, { useState, useContext } from 'react'
import { parseCookies } from 'nookies'
import { useQuery } from '@tanstack/react-query'

import { UserContext } from 'context/userContext'
import { useWindowMessage } from 'hooks/useWindowMessage'
import { setToken, setRefreshToken, getProfile, useMe } from 'services/auth'
import { getBenefits } from 'services/benefits'
import { getNavigatorLocation } from 'services/geoService'

const events = {
  onAuthenticateWebView: ({ token, refreshToken }) => {
    setToken(token)
    setRefreshToken(refreshToken)
  }
}

const UserProvider = ({ children }) => {
  useWindowMessage(events)

  const cookies = parseCookies()
  const [shouldAnswerVitCare, setShouldAnswerVitCare] = useState(false)

  const { data: user, refetch: refetchMe } = useMe()

  const { data: benefits, refetch: refetchBenefits } = useQuery({
    queryKey: ['benefits', user?.id],
    enabled: !!user && !isProfessional,
    queryFn: () => getBenefits(user?.id),
    select: ({ data }) => data.content
  })

  const { data: location } = useQuery({
    queryKey: ['location'],
    queryFn: () => getNavigatorLocation(),
    select: ({ coords }) => {
      return {
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    }
  })

  const isUserLoggedIn = !!user
  const isUserB2B = !!user?.contract || cookies?.contractId || cookies?.['@vittude:user']?.contract
  const isProfessional = user?.role === 'professional'
  const shouldHideConsultationValue = user?.contract?.features?.hide_consultation_value
  const isPresencialConsultationEnabled = user?.contract?.features?.presential_consultation_enabled

  return (
    <UserContext.Provider
      value={{
        user: user || getProfile(),
        benefits,
        location,
        refetchBenefits,
        isUserLoggedIn,
        isUserB2B,
        shouldAnswerVitCare,
        setShouldAnswerVitCare,
        isProfessional,
        shouldHideConsultationValue,
        isPresencialConsultationEnabled,
        refetchMe
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser }
